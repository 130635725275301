import ViewHome from '@/views/ViewHome'

export default [
  {
    path: '/',
    name: 'home',
    component: ViewHome,
    meta: { requiresAuth: true },
  },
]
