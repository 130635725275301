<script>
import { HorizontalBar, mixins } from 'vue-chartjs'

export default {
  extends: HorizontalBar,
  mixins: [mixins.reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: () => {
        return {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
            position: 'bottom',
            labels: {
              fontColor: 'rgb(255, 255, 255)',
            },
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontColor: 'white',
                  beginAtZero: true,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  fontColor: 'white',
                  beginAtZero: true,
                },
              },
            ],
          },
        }
      },
    },
  },
  mounted() {
    this.renderChart(this.chartData, this.options)
  },
}
</script>
