<template>
  <fw-modal :active.sync="isActive" :can-cancel="true" size="7xl" @close="closeModal">
    <div class="flex flex-col sm:flex-row gap-10 w-full">
      <div class="flex-1 sm:w-64">
        <fw-heading size="h2" class="mb-4">Entrar numa sala</fw-heading>
        <fw-label>Indique o ID da sala</fw-label>
        <form class="flex flex-col gap-3" @submit.prevent="checkEntryAndGo">
          <div>
            <b-input id="entrykey" v-model="entryKeyToJoin" placeholder="ID da sala ou exame" />
          </div>
          <div v-if="errorMessage" class="text-center p-2 bg-red-500 bg-opacity-10 rounded-lg text-red-900 text-sm">
            {{ errorMessage }}
          </div>
          <div>
            <fw-button
              type="primary"
              size="md"
              :disabled="!entryKeyToJoin || loadingCheckEntry"
              :loading="loadingCheckEntry"
              expanded
              @click.native="checkEntryAndGo"
              >Entrar</fw-button
            >
          </div>
        </form>
      </div>
      <div v-if="latestEntriesJoined.length" class="w-full sm:w-64">
        <fw-panel title="Histórico" :counter="latestEntriesJoined.length">
          <template #default>
            <div class="overflow-auto max-h-56">
              <template v-for="entry in latestEntriesJoined">
                <button
                  :key="entry.$index"
                  class="text-sm px-3 py-1.5 font-medium rounded-md bg-gray-50 hover:bg-gray-100 text-left w-full my-1"
                  @click="findEntryAndGo(entry.key, entry.type)"
                >
                  <v-clamp autoresize :max-lines="1">
                    {{ entry.title }}
                  </v-clamp>
                  <div v-if="entry.type == 'exam'" class="text-gray-500">Exame {{ entry.key }}</div>
                  <div v-else class="text-xs text-gray-500">
                    {{ entry.key_alias }}
                  </div>
                </button>
              </template>
            </div>
          </template>
          <template #toolbar>
            <a class="text-sm text-gray-500" @click="clearHistory">Limpar</a>
          </template>
        </fw-panel>
      </div>
    </div>
  </fw-modal>
</template>

<script>
import ServiceExams from '@/fw-modules/fw-core-vue/exams/services/ServiceExams'
import ServiceMeetings from '@/fw-modules/fw-meetings-vue/services/ServiceMeetings'

const OLD_HISTORY_KEY = 'history.meetings' // TODO delete (someday)
const HISTORY_KEY = 'history.entries'

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isActive: this.show || false,
      entryKeyToJoin: null,
      latestEntriesJoined: [],
      duplicatedKeys: [],
      errorMessage: null,
      loadingCheckEntry: false,
    }
  },

  computed: {
    loggedUser() {
      return this.$store.getters.getUser
    },
    isMobile() {
      return this.window.width < 640
    },
  },

  watch: {
    show(value) {
      this.isActive = value
    },
  },

  mounted() {
    this.getHistory()
  },

  methods: {
    closeModal() {
      this.isActive = false
      this.entryKeyToJoin = null
      this.$emit('close')
    },

    clearHistory() {
      localStorage.removeItem(OLD_HISTORY_KEY)
      localStorage.removeItem(HISTORY_KEY)
      this.latestEntriesJoined = []
    },

    async findMeeting(key) {
      try {
        const meeting = await ServiceMeetings.getMeeting(key)
        return {
          type: 'meeting',
          key: meeting.key,
          title: meeting.title,
          key_alias: meeting.key_alias,
          item_type: meeting.item_type,
        }
      } catch {
        console.debug(`Failed to find meeting ${key}`)
      }
    },
    async findExam(key) {
      try {
        const exam = await ServiceExams.getExam(key)
        return {
          type: 'exam',
          key: exam.key,
          title: exam.title || exam.titles.pt,
          key_alias: exam.key,
          item_type: 'exam',
        }
      } catch {
        console.debug(`Failed to find exam ${key}`)
      }
    },
    async findEntryAndGo(key, type = null) {
      if (!key) return

      this.loadingCheckEntry = true
      this.duplicatedKeys = []
      this.errorMessage = null

      try {
        const futures = []
        if (!type || type == 'exam') {
          futures.push(this.findExam(key))
        }
        if (!type || type == 'meeting') {
          futures.push(this.findMeeting(key))
        }

        const entries = []
        if (futures.length) {
          for (let entry of await Promise.all(futures)) {
            if (entry) {
              entries.push(entry)
            }
          }
        }

        if (!entries.length) {
          // Remove from history
          let previousLength = this.latestEntriesJoined.length
          for (let i = previousLength - 1; i >= 0; i--) {
            if (this.latestEntriesJoined[i].key == key) {
              this.latestEntriesJoined.splice(i, 1)
            }
          }

          if (previousLength > this.latestEntriesJoined.length) {
            localStorage.setItem(HISTORY_KEY, JSON.stringify(this.latestEntriesJoined))
          }

          this.errorMessage = 'O ID da sala não existe.'
        } else if (entries.length > 1) {
          this.duplicatedKeys = entries
        } else {
          this.goToEntry(entries[0])
        }
      } finally {
        this.loadingCheckEntry = false
      }
    },
    goToEntry(entry) {
      // Check if exists - if not, add to history
      if (entry.type !== 'meeting' || entry.key != this.loggedUser.meeting.key) {
        let exists = false
        for (let historyEntry of this.latestEntriesJoined) {
          if (historyEntry.type === entry.type && historyEntry.key === entry.key) {
            historyEntry.title = entry.title
            historyEntry.key_alias = entry.key_alias
            historyEntry.item_type = entry.item_type
            exists = true
            break
          }
        }
        if (!exists) {
          this.latestEntriesJoined.push(entry)
        }

        // Update local history
        localStorage.setItem(HISTORY_KEY, JSON.stringify(this.latestEntriesJoined.slice(0, 50)))
      }

      // Go to entry hall
      if (entry.type == 'exam') {
        this.$router.push({ name: 'exams-v1-join', params: { key: entry.key } })
      } else {
        this.$router.push({ name: 'live', params: { key: entry.key_alias || entry.key } })
      }
    },
    async checkEntryAndGo() {
      if (this.entryKeyToJoin) await this.findEntryAndGo(this.entryKeyToJoin)
    },

    getHistory() {
      let historyJSON = localStorage.getItem(OLD_HISTORY_KEY)
      if (historyJSON) {
        localStorage.setItem(HISTORY_KEY, historyJSON)
        localStorage.removeItem(OLD_HISTORY_KEY)
      } else {
        historyJSON = localStorage.getItem(HISTORY_KEY)
      }

      this.latestEntriesJoined = historyJSON ? JSON.parse(historyJSON) : []
    },
  },
}
</script>
