import Janus from './janus'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

var probo = new (function() {
  this.notnull = function(obj) {
    return obj !== undefined && obj !== null
  }
})()

probo.Subscriber = function(args) {
  var self = this

  this.publisher = args.publisher
  this.session = args.session
  this.private_id = args.private_id
  this.id = args.id
  this.feed_display = args.display
  this.type = args.type
  this.attendee = args.attendee
  this.stream_listener = args.stream_listener
  this.event_handler = args.event_handler
  this.simulcast_listener = args.simulcast_listener
  this.slow_link = args.slow_link

  this.handle = null
  this.stream = null

  this.attach = function() {
    self.session.janus.attach({
      plugin: self.session.plugin_name,
      opaqueId: self.session.opaque_id,
      success: self._success,
      onmessage: self._onmessage,
      onremotestream: self._onremotestream,
      slowLink: self.slow_link ? self._slow_link : null,
    })
    return self
  }

  this._slow_link = function(uplink, lost) {
    self.slow_link(self.id, uplink, lost)
  }

  this._success = function(plugin_handle) {
    self.handle = plugin_handle
    self.handle.send({
      message: {
        request: 'join',
        ptype: 'subscriber',
        room: self.session.room_id,
        feed: self.id,
        private_id: self.private_id,
      },
    })
    return self
  }

  this._onremotestream = function(stream, change) {
    self.stream = stream

    let listener = self.stream_listener
    if (probo.notnull(listener)) {
      if (change === 'onmute' || !stream.active) {
        if (probo.notnull(listener.mute)) {
          listener.mute({
            subscriber: self,
          })
        }
      } else {
        listener.on(self)
      }
    }
  }

  this._onmessage = function(msg, jsep) {
    var event = msg['videoroom']
    if (probo.notnull(event)) {
      if (event === 'attached') self._onmessage_attached(msg)
      else if (event === 'event') self._onmessage_event(msg)

      if (probo.notnull(self.event_handler)) self.event_handler(self, msg)
    }

    if (probo.notnull(jsep)) self._onmessage_sdp(msg, jsep)
  }

  this._onmessage_attached = function(msg) {
    console.assert(msg['id'] === self.id)
    // @@ Successfully attached; may display spinner
  }

  this._onmessage_event = async function(msg) {
    if (probo.notnull(msg['substream']) || probo.notnull(msg['temporal'])) {
      if (probo.notnull(self.simulcast_listener)) self.simulcast_listener(self, msg)
    } else if (msg.started === 'ok' && self.stream_listener && self.stream_listener.started) {
      self.stream_listener.started(self)
    } else if (msg.error_code === 428) {
      if (self.handler) {
        console.debug(`Stream ${self.id} already started`)
      } else if (!self.publisher.shouldRetryHandler(self)) {
        console.debug(`Stream ${self.id} not found, was already ended or retry failed`)
      } else {
        console.warn(`Stream ${self.id} not found, retrying`)
        await utils.sleep(2000)
        self.attach()
      }
    }
  }

  this._onmessage_sdp = function(msg, jsep) {
    self.jsep = jsep
    self.handle.createAnswer({
      jsep: jsep,
      media: {
        audioSend: false,
        audioRecv: self.type !== 'camera',
        videoSend: false,
        videoRecv: self.type !== 'audio',
      },
      success: function(jsep) {
        self.handle.send({
          message: { request: 'start', room: self.session.room_id },
          jsep: jsep,
        })
      },
    })
  }

  this.update = function(want_video, want_audio) {
    self.handle.createAnswer({
      jsep: self.jsep,
      media: {
        audioSend: false,
        audioRecv: want_audio,
        removeAudio: !want_audio,
        videoSend: false,
        videoRecv: want_video,
        removeVideo: !want_video,
      },
      success: function(jsep) {
        self.handle.send({
          message: { request: 'start', room: self.session.room_id },
          jsep: jsep,
        })
      },
    })
  }
}

probo.Publisher = function(args) {
  var self = this

  this.session = args.session
  this.publish_media = args.publish_media
  this.use_simulcast = !!args.use_simulcast
  this.master = args.master
  this.stream_listener = args.local_stream_listener
  this.remote_stream_listener = args.remote_stream_listener
  this.event_handler = args.event_handler
  this.simulcast_listener = args.simulcast_listener
  this.media_state = args.media_state
  this.slow_link = args.slow_link
  this.subscriber_slow_link = args.subscriber_slow_link
  this.token = args.token

  this.handle = null
  this.handleRetried = {}
  this.id = null
  this.private_id = null
  this.subscribers = {}

  this.attach = function() {
    let options = {
      plugin: self.session.plugin_name,
      opaqueId: self.session.opaque_id,
      success: self._success,
      error: self._error,
      onmessage: self._onmessage,
      onlocalstream: self._onlocalstream,
      mediaState: self.media_state,
      slowLink: self.slow_link,
    }
    if (self.stream_listener) options.onlocalstream = self.stream_listener.on
    self.session.janus.attach(options)
    return self
  }

  this._success = function(plugin_handle) {
    self.handle = plugin_handle
    self.handle.send({
      message: {
        request: 'join',
        ptype: 'publisher',
        room: self.session.room_id,
        display: self.session.user_name,
        token: self.token,
      },
    })
    return self
  }

  this._error = function(message) {
    if (self.stream_listener && self.stream_listener.off) self.stream_listener.off(message)
  }

  this._onmessage = function(msg, jsep) {
    var event = msg['videoroom']
    if (probo.notnull(event)) {
      if (event === 'joined') self._onmessage_joined(msg)
      else if (event === 'event') self._onmessage_event(msg)
      else if (probo.notnull(msg['leaving'])) self._onmessage_leaving(msg)
      else if (event === 'talking') self._onmessage_talking(msg)
      else if (event === 'stopped-talking') self._onmessage_stopped_talking(msg)
      else console.log('*** unhandled!', msg)

      if (event === 'joined' || event === 'event') {
        if (self.master) {
          if (probo.notnull(msg['leaving'])) self._onmessage_leaving(msg)
        }
      }

      if (probo.notnull(self.event_handler)) self.event_handler(self, msg)
    }

    if (probo.notnull(jsep)) self._onmessage_sdp(msg, jsep)
  }

  this._onmessage_joined = function(msg) {
    self.id = msg['id']
    self.private_id = msg['private_id']
    if (args.success) args.success()

    if (self.publish_media['audioSend'] || self.publish_media['videoSend']) {
      self.handle.createOffer({
        media: self.publish_media,
        simulcast: self.use_simulcast,
        error: self._error,
        success: function(jsep) {
          self.handle.send({
            message: {
              request: 'configure',
              audio: self.publish_media['audioSend'],
              video: self.publish_media['videoSend'],
            },
            jsep: jsep,
          })
        },
      })
    }
  }

  this._onmessage_leaving = function(msg) {
    let id = msg['leaving']
    // Check if we're dealing with a local publisher, i.e. the screen share:
    if (self.session.leftById(id)) return

    // No, we're dealing with a remote publisher
    let subscriber = self.subscribers[msg['leaving']]
    if (probo.notnull(subscriber)) {
      self.remote_stream_listener.off({ subscriber: subscriber })
      delete self.subscribers[msg['leaving']]
    }
  }

  this._onmessage_talking = function(msg) {
    if (probo.notnull(self.remote_stream_listener) && probo.notnull(self.remote_stream_listener.talking)) {
      self.remote_stream_listener.talking({
        subscriber: self.subscribers[msg['id']],
        message: msg,
      })
    }
  }

  this._onmessage_stopped_talking = function(msg) {
    if (probo.notnull(self.remote_stream_listener) && probo.notnull(self.remote_stream_listener.stopped_talking)) {
      self.remote_stream_listener.stopped_talking({
        subscriber: self.subscribers[msg['id']],
        message: msg,
      })
    }
  }

  this._onmessage_event = function(msg) {
    if (msg['error'] !== undefined && msg['error'] !== null) {
      Janus.error(msg['error'])
    } else if (probo.notnull(msg['unpublished'])) {
      if (msg['unpublished'] === 'ok') {
        self.handle.hangup()
      } else {
        let key = msg['unpublished']
        let subscriber = self.subscribers[key]
        if (probo.notnull(subscriber) && subscriber.handle) {
          // self.remote_stream_listener.off({
          //   subscriber: subscriber
          // });
          subscriber.handle.detach()
        }
      }
    } else if (probo.notnull(msg['reason']) && msg['reason'] == 'kicked') {
      self.session.detach_all()
    }
  }

  this._onmessage_sdp = function(msg, jsep) {
    self.handle.handleRemoteJsep({ jsep: jsep })
    // @@ Check if any of the media we wanted to publish has been
    //    rejected
  }

  this.addSubscriber = function(type, id, attendee) {
    console.debug('adding subscriber', type, id, !!self.subscribers[id], attendee)
    if (self.subscribers[id]) return

    let subscriber = new probo.Subscriber({
      publisher: self,
      session: self.session,
      private_id: self.private_id,
      id: id,
      feed_display: `${type}-${id}`,
      stream_listener: self.remote_stream_listener,
      event_handler: self.event_handler,
      simulcast_listener: self.simulcast_listener,
      slow_link: self.subscriber_slow_link,
      type: type,
      attendee: attendee,
    }).attach()
    self.subscribers[id] = subscriber
  }

  this.shouldRetryHandler = function(subscriber) {
    if (self.subscriberEnded(subscriber)) {
      return false
    } else if (!self.handleRetried[subscriber.id]) {
      self.handleRetried[subscriber.id] = 1
      return true
    } else {
      self.handleRetried[subscriber.id] += 1
      return self.handleRetried[subscriber.id] <= 3
    }
  }
  this.subscriberEnded = function(subscriber) {
    const subscriberRef = self.subscribers[subscriber.id]
    return !subscriberRef || subscriberRef !== subscriber
  }
  this.removeSubscriber = function(type, id) {
    const subscriber = self.subscribers[id]
    console.debug('removing subscriber', type, id, !!subscriber)
    if (subscriber) {
      if (subscriber.handle) subscriber.handle.detach()
      self.remote_stream_listener.off({ subscriber: subscriber })
      delete self.subscribers[id]
    }
  }

  this.left = function() {
    if (self.handle) self.handle.detach()
    if (self.stream_listener && probo.notnull(self.stream_listener.off)) self.stream_listener.off()
  }
}

export default probo
