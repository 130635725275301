<template>
  <BlockEditor
    :id="id"
    :value="value"
    :placeholder-text="placeholderText"
    :disabled="disabled"
    :editor-class="editorClass"
    :autofocus="autofocus"
    @input="$emit('input', $event)"
    @focus="$emit('focus', $event)"
  />
</template>

<script>
import BlockEditor from '@/fw-modules/fw-core-vue/posts/components/blocks/BlockEditor.vue'

export default {
  name: 'HtmlEditor',
  components: {
    BlockEditor,
  },
  props: {
    id: {
      type: String,
      default: 'htmlEditor',
    },
    value: {
      type: String,
      default: '',
    },
    placeholderText: {
      type: String,
      default: 'Escreva aqui...',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    editorClass: {
      type: String,
      default: '',
    },
    formatTools: {
      type: Array,
      default: () => ['bold', 'italic', 'underline', 'bulletList', 'codeBlock'],
    },
    extraFormatTools: {
      type: Array,
      default: () => [],
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
