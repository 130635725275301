<template>
  <b-modal
    :active.sync="isActive"
    :on-cancel="closeModal"
    has-modal-card
    aria-role="dialog"
    aria-modal
    class="meetings-modal-pods-enter-queue"
  >
    <div class="modal-card">
      <section class="modal-card-body">
        <div class="is-flex is-flex-space-between">
          <div class="is-flex is-flex-align-center">
            <div class="label is-size-3">Opções de desempenho</div>
          </div>
          <div>
            <b-button type="is-text" icon-right="times" @click="closeModal">Fechar</b-button>
          </div>
        </div>

        <div v-if="withNetworkIssues || camera.withCPUIssues || screenShare.withCPUIssues">
          <div class="has-margin-top has-margin-bottom-medium">
            Detectámos algumas dificuldades no seu computador ou ligação que podem comprometer a sua experiência nesta
            plataforma:
          </div>
          <div v-if="camera.withCPUIssues || screenShare.withCPUIssues" class="notification is-warning">
            <div class="is-size-4">O seu computador / dispositivo está com processamento elevado.</div>
            <div class="has-text-small has-margin-top-small has-text-weight-normal">
              Esta situação poderá criar vários constragimentos na utilização de funcionalidades básicas,
              <strong>redução na qualidade de vídeo e/ou áudio recebido</strong> e até interrupção da transmissão.
            </div>
          </div>
          <div v-if="withNetworkIssues" class="notification is-warning">
            <div class="is-size-4">
              A sua ligação à internet parece instável.
            </div>
            <div class="has-text-small has-margin-top-small has-text-weight-normal">
              Caso existam falhas na ligação, poderá começar a notar atrasos na receção de vídeo-áudio e até
              desfazamento entre ambos. Esta plataforma irá procurar gerir estas oscilações, em tempo real, ajustando a
              transmissão recebida/enviada, conforme as melhores condições disponíveis.
            </div>
          </div>
          <hr />
        </div>

        <div class="has-margin-top">
          <div v-if="withNetworkIssues || camera.withCPUIssues || screenShare.withCPUIssues">
            <h3 class="has-margin-bottom-small">O que fazer?</h3>
            <div class="has-margin-bottom-medium has-text-small">
              Para melhorar a experiência, considere as seguintes opções (clique para ativar):
            </div>
          </div>
          <div v-else class="has-margin-bottom has-text-small">
            Este painel permite reduzir algumas condições de transmissão desta sessão, caso tenha uma ligação à rede
            limitada ou um dispositivo com menor capacidade de processamento.
          </div>
          <div class="has-margin-top-medium has-margin-bottom label">Opções a considerar</div>
          <div v-if="attendee.with_hd && (camera.active || screenShare.active)" class="has-margin-small">
            <b-checkbox @input="$emit('toggle-hd-mode')">
              <span style="width: 20px" class="tag is-light is-small has-margin-right-small">HD</span>
              <span>Desativar alta-definição do meu vídeo e partilha de ecrã</span>
            </b-checkbox>
          </div>
          <div v-if="(!attendee.with_hd && camera.active) || attendee.with_low_quality" class="has-margin-small">
            <div class="is-flex is-flex-align-center">
              <b-checkbox v-model="configs.selfLowestQuality" @input="camera.toggleQuality()">
                Reduzir qualidade do meu vídeo
              </b-checkbox>
              <b-button
                size="is-small"
                type="is-text"
                aria-label="Mais info"
                icon-left="question-circle"
                @click="infoBoxes.lowerVideoQuality = !infoBoxes.lowerVideoQuality"
              ></b-button>
            </div>
            <div v-if="infoBoxes.lowerVideoQuality" class="has-text-small has-text-muted">
              O ajuste de qualidade é feito automaticamente, conforme as condições de rede disponíveis. Com esta opção
              poderá forçar que o sinal de vídeo enviado seja sempre reduzido.
            </div>
          </div>
          <div class="has-margin-small">
            <div class="is-flex is-flex-align-center">
              <b-checkbox v-model="configs.lowestQuality" @input="$emit('toggle-lowest-quality')">
                Reduzir qualidade do vídeo dos participantes.
              </b-checkbox>
              <b-button
                size="is-small"
                type="is-text"
                aria-label="Mais info"
                icon-left="question-circle"
                @click="infoBoxes.lowerPodsQuality = !infoBoxes.lowerPodsQuality"
              ></b-button>
            </div>
            <div v-if="infoBoxes.lowerPodsQuality" class="has-text-small has-text-muted">
              Esta opção é ativada apenas no seu dispositivo e irá ajudar caso esteja com uma ligação à rede limitada.
            </div>
          </div>
          <div class="has-margin-small">
            <div class="is-flex is-flex-align-center">
              <b-checkbox v-model="configs.camerasDisabled" @input="$emit('toggle-all-videos')">
                Colocar em pausa o vídeo de todos participantes.
              </b-checkbox>
              <b-button
                size="is-small"
                type="is-text"
                aria-label="Mais info"
                icon-left="question-circle"
                @click="infoBoxes.pausePods = !infoBoxes.pausePods"
              ></b-button>
            </div>
            <div v-if="infoBoxes.pausePods" class="has-text-small has-text-muted">
              Esta opção é ativada apenas no seu dispositivo e irá ajudar caso tenha uma ligação à rede limitada ou
              detecte que o seu dispositivo está com dificuldade de processamento.
            </div>
          </div>
          <div v-if="camera.active" class="has-margin-small">
            <b-checkbox @input="camera.toggle()">
              Desligar o meu vídeo
            </b-checkbox>
          </div>
          <div
            v-if="pageSettings.current > pageSettings.warnAfter && podsLength > pageSettings.warnAfter"
            class="has-margin-small has-margin-top is-flex is-flex-align-top"
          >
            <b-checkbox :disabled="true" class="is-marginless" @input="camera.toggle()"></b-checkbox>
            <div>
              <span>Reduzir o número de participantes por página</span><br />
              <a href="" class="has-text-small" @input.prevent="togglePodsGalleryConfig">Configurar</a>
            </div>
          </div>
          <div class="has-text-tiny has-margin-top-medium">
            * Estas opções são aplicadas apenas à sessão virtual atual.
          </div>
        </div>
        <hr class="has-margin-top has-margin-bottom-small" />
        <div>
          <b-button
            size="is-small"
            type="is-text"
            :icon-right="!infoBoxes.additionalInfo ? 'chevron-down' : 'chevron-up'"
            @click="infoBoxes.additionalInfo = !infoBoxes.additionalInfo"
            >Notas adicionais</b-button
          >
          <div
            v-if="infoBoxes.additionalInfo"
            class="has-margin-top-small has-text-small content has-background-light has-padding"
          >
            <div>
              1. Em sessões com muitos participantes, procure recorrer à utilização de um computador ou tablet
              (recente). Esta plataforma funciona no navegador (browser) e está limitada na utilização de recursos do
              sistema, ao contrário de outras plataformas que têm aplicações dedicadas.
            </div>
            <div class="has-margin-top">
              2. Procure garantir que as condições de internet são adequadas. Em plataformas de vídeo, qualquer pequena
              falha (ou sequência de muitas) poderá causar uma má experiência para si e para os participantes remotos
              que estão virtualmente consigo.
              <strong>A velocidade da ligação é menos importante que a sua estabilidade.</strong>
            </div>
          </div>
        </div>
      </section>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    attendee: Object,
    showQualityModal: Boolean,
    screenShare: Object,
    pageSettings: Object,
    podsLength: Number,
    camera: Object,
    allowToDisableCameras: Boolean,
    camerasDisabled: Boolean,
    lowestQuality: Boolean,
    withNetworkIssues: Boolean,
  },

  data() {
    return {
      isActive: this.showQualityModal,
      configs: {
        camerasDisabled: this.camerasDisabled,
        lowestQuality: this.lowestQuality,
        selfLowestQuality: this.attendee.with_low_quality,
      },
      infoBoxes: {
        lowerVideoQuality: false,
        lowerPodsQuality: false,
        pausePods: false,
        additionalInfo: false,
      },
    }
  },

  watch: {
    showQualityModal(value) {
      this.isActive = value
    },
    attendee: {
      handler: function(attendee) {
        this.configs.selfLowestQuality = attendee.with_low_quality
      },
    },
  },

  methods: {
    closeModal() {
      this.$emit('toggle-quality-modal')
      this.$emit('close', false)
    },
    togglePodsGalleryConfig() {
      this.closeModal()
      setTimeout(() => {
        this.$emit('toggle-pods-gallery-config')
      }, 100)
    },
  },
}
</script>
