<template>
  <div>
    <div v-if="step === 'choose-subject'" class="h-full flex flex-col gap-4">
      <div>
        <fw-heading muted>Tópicos de ajuda</fw-heading>
      </div>
      <div>
        <ContextualSearch
          type="minimal"
          :loading="loading.subjects"
          :show-filters="false"
          @search="getSupportSubjects"
        />
      </div>
      <div class="text-xs text-gray-500 text-center">
        Por favor, escolha um tópico para contextualizar o seu pedido de ajuda.
      </div>
      <div>
        <b-tabs v-model="chooseQueueActiveTab" :animated="false" @input="getSupportSubjects">
          <b-tab-item :label="currentApp ? `${currentApp.title} (contexto atual)` : 'Contexto atual'" value="this-app">
            <PanelSubjects
              :loading="loading.subjects"
              :pagination="pagination.subjects"
              :subjects="subjects"
              :spaces="spaces"
              @open="selectSubject"
            />
          </b-tab-item>
          <b-tab-item label="Todos" value="all">
            <PanelSubjects
              :loading="loading.subjects"
              :pagination="pagination.subjects"
              :subjects="subjects"
              :spaces="spaces"
              @open="selectSubject"
            />
          </b-tab-item>
        </b-tabs>
      </div>
      <div class="flex justify-end -mt-2">
        <fw-button type="link-muted" @click.native="$emit('close', true)">{{ $t('cancel') }}</fw-button>
      </div>
    </div>
    <div v-else-if="step === 'subject-info' && activeSubject">
      <div>
        <fw-heading muted>Sobre o tópico</fw-heading>
      </div>
      <div class="bg-gray-100 px-10 py-5 rounded-xl my-3 flex flex-col gap-5">
        <div class="text-center">
          <fw-heading size="h3">{{ activeSubject.title }}</fw-heading>
          <div v-if="activeSubject.description" class="font-medium text-base text-gray-500 mt-2">
            {{ activeSubject.description }}
          </div>
        </div>
        <div class="flex flex-col gap-3 justify-center items-center">
          <div>
            <fw-label marginless>Equipa responsável</fw-label>
          </div>
          <div
            v-if="activeQueueWorkersFiltered?.length > 1"
            class="flex justify-center"
            :class="{
              'gap-1': activeQueueWorkersFilteredLimited.length > 3,
              'gap-3': activeQueueWorkersFilteredLimited.length <= 3,
            }"
          >
            <template v-for="user in activeQueueWorkersFilteredLimited">
              <fw-avatar :key="user.id" size="base" :user="user" />
            </template>
            <div
              v-if="activeSubjectData?.workers.length > activeQueueWorkersFiltered?.length"
              class="p-1 rounded-xl bg-gray-300 w-12 flex items-center justify-center font-medium text-gray-500"
            >
              <span class="-ml-0.5">+{{ activeSubjectData?.workers.length - activeQueueWorkersFiltered.length }}</span>
            </div>
          </div>
          <div v-if="activeSubjectData.space?.team_name">
            <fw-heading size="md">{{ activeSubjectData.space.team_name }}</fw-heading>
          </div>
          <div v-if="activeSubjectData.queue?.title">
            <fw-tag type="light-border-box" size="sm">
              Fila <span class="ml-1 font-mono">{{ activeSubjectData.queue.prefix }}</span>
            </fw-tag>
          </div>
        </div>
        <div class="text-sm text-gray-500 text-center max-w-xs mx-auto">
          Clique em <b>Seguinte</b> para avançar para o formulário de pedido de ajuda para esta fila.
        </div>
      </div>
      <div class="flex justify-end">
        <fw-button type="link-muted" @click.native="$emit('close', true)">{{ $t('cancel') }}</fw-button>
        <fw-button type="link" wider icon-right="arrow-right" @click.native="openTaskForm">Seguinte</fw-button>
      </div>
    </div>
    <div v-else-if="step === 'fill-task-form' && activeSubject" class="flex flex-col gap-2">
      <fw-panel :title="$t('newTask')" :loading="savingData" :loading-error="savingDataError" after-loading-checked>
        <template #toolbar>
          <div class="flex gap-1 items-center">
            <div v-if="$v.$error" class="p-1 mx-5 text-red-500 flex items-center gap-1 text-sm font-medium">
              <fw-icon-error-warning class="w-6 h-6"></fw-icon-error-warning>
              <span class="hidden lg:block">{{ $t('thereAreErrors') }}</span>
            </div>
          </div>
        </template>
      </fw-panel>

      <div>
        <fw-label>{{ $t('title.label') }}</fw-label>
        <TextInput
          id="taskTitle"
          v-model="task.title"
          :minlength="2"
          :maxlength="250"
          :class="{
            error: $v.task.title.$error,
          }"
        />
        <fw-tip v-if="$v.task.title.$error" error>
          <span v-if="!$v.task.title.required">{{ $t('title.required') }}</span>
          <span v-else>{{ $t('title.invalid') }}</span>
        </fw-tip>
      </div>

      <!-- <div v-if="subjects && subjects.length">
        <fw-label>{{ $t('subject.label') }}</fw-label>
        <multiselect
          v-model="task.type"
          :options="subjects"
          :show-labels="false"
          :clear-on-select="false"
          :allow-empty="false"
          :placeholder="$t('subject.required')"
          :multiple="false"
          :max-height="200"
          track-by="key"
          label="text"
        ></multiselect>

        <fw-tip v-if="$v.task.type.$error" error>
          <span v-if="!$v.task.type.required">{{ $t('subject.required') }}</span>
          <span v-else>{{ $t('subject.invalid') }}</span>
        </fw-tip>
      </div> -->

      <slot name="context" :task="task"></slot>

      <div>
        <fw-label>{{ $t('description.label') }}</fw-label>
        <HtmlEditor
          v-model="task.description"
          editor-class="min-h-40"
          :minlength="2"
          :maxlength="250"
          :class="{
            error: $v.task.description.$error,
          }"
          :help="$t('description.help')"
        >
        </HtmlEditor>

        <fw-tip v-if="$v.task.description.$error" error>
          <span v-if="!$v.task.description.required">{{ $t('description.required') }}</span>
          <span v-else>{{ $t('description.invalid') }}</span>
        </fw-tip>
      </div>

      <div>
        <fw-label>{{ $t('files.label') }}</fw-label>
        <div class="mb-2">
          <div v-if="task.files && task.files.length > 0" class="files mb-1.5">
            <RecordFileEntry
              v-for="(file, f) in task.files"
              :key="file.key"
              :can-edit="true"
              :allow-classified="false"
              :file="file"
              @save="saveFile(f, $event)"
              @remove="removeFile(f)"
              @download="downloadFile(file)"
            />
          </div>
          <Uploader
            :label="$t('files.upload')"
            :is-docked="true"
            layout="minimal"
            reference-id="uploader_update"
            allowed="all"
            :clear-after="true"
            input-id="upload_input"
            :files.sync="updateFilesToUpload"
            :size="0"
            :new-file-context="{}"
            file-type="file"
            file-code=""
            class="cursor-pointer update-modal-uploader"
            uploader-class="w-full rounded"
            @upload="uploaded"
          />
        </div>
      </div>

      <div class="mb-2 group">
        <fw-label marginless>Tópico, Fila e Espaço</fw-label>
        <div class="flex gap-1 mt-1 opacity-70 group-hover:opacity-100">
          <fw-icon-info class="w-5 h-5"></fw-icon-info>
          <fw-heading size="sm">{{ activeSubject.title }}</fw-heading>
        </div>
        <div class="text-xs opacity-70 group-hover:opacity-100 pl-5">
          O seu pedido de ajuda será criado na fila <b>{{ activeSubjectData.queue.title }}</b
          >, no espaço <b>{{ activeSubjectData.space.title }}</b
          >. Depois de submetido, poderá acompanhar o seu progresso nesta plataforma, através da opção <b>Suporte</b>,
          disponível na página inicial ou no canto superior direito, junto às opções da sua conta.
        </div>
      </div>

      <div class="flex items-center justify-end gap-5 mt-2">
        <fw-button type="link-muted" @click.native="$emit('close')">
          {{ $t('cancel') }}
        </fw-button>

        <fw-button type="primary" wider @click.native="save">
          {{ $t('save') }}
        </fw-button>
      </div>
    </div>
    <div v-else-if="step === 'success'" class="text-gray-600 flex flex-col gap-3 items-center justify-center pt-4 pb-2">
      <CheckmarkSuccess />
      <fw-heading size="h3" class="text-primary">O seu pedido foi submetido com sucesso.</fw-heading>
      <div class="text-center text-sm flex flex-col gap-1">
        <div class="text-medium">Voltaremos ao seu contacto tão breve quanto possível.</div>
        <div class="font-semibold">Muito obrigado.</div>
      </div>
      <div class="mt-2">
        <fw-button type="xlight" wider @click.native="$emit('close', true)">Fechar janela</fw-button>
      </div>
    </div>
    <fw-panel-info debug label="Data (raw)">
      <json-viewer
        :value="{ step, currentApp, apps, subjects, spaces, task, activeSubject, activeSubjectData }"
      ></json-viewer>
    </fw-panel-info>
  </div>
</template>

<script>
import TextInput from '@/fw-modules/fw-core-vue/ui/components/form/TextInput'
import HtmlEditor from '@/fw-modules/fw-core-vue/ui/components/form/HtmlEditor'
import Uploader from '@/fw-modules/fw-core-vue/storage/components/PathUploader.vue'
import RecordFileEntry from '@/fw-modules/fw-core-vue/ui/components/form/RecordFileEntry'
import { required, requiredIf, maxLength, minLength } from 'vuelidate/lib/validators'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import ContextualSearch from '@/fw-modules/fw-core-vue/ui/components/search/ContextualSearch'
import PanelSubjects from '@/fw-modules/fw-core-vue/tasks/components/panels/PanelSubjects'
import ServiceTasks from '@/fw-modules/fw-core-vue/tasks/services/ServiceTasks'
import { FW_APPS } from '@/fw-modules/fw-core-vue/config'
import CheckmarkSuccess from '@/fw-modules/fw-core-vue/ui/components/animation/CheckmarkSuccess'

export default {
  components: {
    TextInput,
    HtmlEditor,
    RecordFileEntry,
    Uploader,
    ContextualSearch,
    PanelSubjects,
    CheckmarkSuccess,
  },

  props: {
    requireContextKey: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      apps: Object.freeze(FW_APPS),
      subjects: [],
      spaces: {},
      pagination: {
        subjects: {
          current_page: 1,
          total_pages: 1,
          active_limit: 30,
          total_items: 0,
        },
      },
      savingData: false,
      savingDataError: false,
      updateFilesToUpload: [],
      procedures: [],
      task: {
        title: '',
        description: '',
        context_type: null,
        context_key: null,
        files: [],
      },
      limit: 30,
      step: 'choose-subject',
      activeSubject: null,

      activeSubjectData: {
        space: null,
        queue: null,
        workers: [],
      },

      chooseQueueActiveTab: 'this-app',
      loading: {
        subjects: false,
        subject: false,
      },
    }
  },

  computed: {
    currentApp() {
      return this.apps?.find(el => el.key == process.env.VUE_APP_KEY)
    },

    loggedUser() {
      return this.$store.getters.getUser
    },
    // Filter workers from the active queue and return just the ones with photo
    activeQueueWorkersFiltered() {
      return this.activeSubjectData?.workers?.filter(el => el.photo) || []
    },
    activeQueueWorkersFilteredLimited() {
      if (!this.activeQueueWorkersFiltered.length) return []

      const workersWithPhoto = this.activeQueueWorkersFiltered
      // Sort randomly
      workersWithPhoto.sort(() => Math.random() - 0.5)
      // Limit to 5 and show number of remaining workers
      if (workersWithPhoto.length > 7) return workersWithPhoto.slice(0, 5)

      return workersWithPhoto
    },
  },

  mounted() {
    this.getSupportSubjects()
  },

  validations() {
    return {
      task: {
        title: { required, min: minLength(2), max: maxLength(250) },
        description: { required, min: minLength(2), max: maxLength(65000) },
        context_key: { required: requiredIf(this.requireContextKey === true) },
      },
    }
  },

  methods: {
    selectSubject(subject) {
      console.log('selectSubject', subject)
      this.getSupportSubjectDetails(subject.key)
      this.step = 'subject-info'
    },

    openTaskForm() {
      this.step = 'fill-task-form'

      this.$nextTick(() => {
        this.$el.querySelector('#taskTitle').focus()
      })
    },

    subjectsPageChanged(page) {
      this.pagination.subjects['current_page'] = page + 1
      this.getSupportSubjects()
    },

    async getSupportSubjects({ term } = {}) {
      this.loading.subjects = true

      await utils.tryAndCatch(this, async () => {
        const result = await ServiceTasks.getSupportTypes({
          all: this.chooseQueueActiveTab === 'all',
          app: this.currentApp?.key,
          page: this.pagination.subjects.current_page,
          limit: this.pagination.subjects.active_limit,
          ...(term ? { query: term } : {}),
        })
        this.subjects = result.types
        this.spaces = result.spaces
        if (result.pagination) {
          this.pagination.subjects = result.pagination
        }
        console.log('result', result)
      })

      this.loading.subjects = false
    },

    async getSupportSubjectDetails(key) {
      this.loading.subject = true

      await utils.tryAndCatch(this, async () => {
        const result = await ServiceTasks.getSupportType(key)
        this.activeSubject = result.type
        this.activeSubjectData = {
          space: result.space,
          queue: result.queue,
          workers: result.queue_workers,
        }
      })

      this.loading.subject = false
    },

    async uploaded(files) {
      let newfiles = []
      for (const file of files) {
        if (file.response.data) {
          if (file.response.status === 'success') {
            newfiles.push(file.response.data.file)
          }
        }
      }

      this.task.files = this.task.files.concat(newfiles)
    },

    async downloadFile(file) {
      const url = ServiceStorage.getFileUrl(file, this.$store.state.session.user.token)
      utils.downloadFile(url, file.filename)
    },

    removeFile(f) {
      this.$buefy.dialog.confirm({
        message: this.$t('deleteFileConfirm'),
        onConfirm: async () => {
          this.task.files.splice(f, 1)
        },
      })
    },

    saveFile(f, updatedFile) {
      console.log('save filename', f, updatedFile)
      this.$set(this.task.files, f, { filename: updatedFile.title, key: updatedFile.key })
    },

    async save() {
      this.$v.$touch()
      if (this.$v.$invalid) return
      if (!this.requireContextKey) {
        delete this.task.context_key
        delete this.task.context_type
      } else if (!this.task.context_key?.key) {
        delete this.task.context_type
      }

      this.savingData = true
      const files = this.task.files.map(el => {
        return { key: el.key, filename: el.filename }
      })

      await utils.tryAndCatch(this, async () => {
        const result = await ServiceTasks.createSupportTask({
          ...this.task,
          files: files,
          type_key: this.activeSubject.key,
          url: window.location.href,
        })
        console.log('createSupportTask', result)
        this.savingData = false
        this.step = 'success'
        this.$emit('update-tasks-list')
      })

      this.savingData = false
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "newTask": "Novo pedido de ajuda",
    "thereAreErrors": "Existem erros no formulário",
    "close": "Fechar",
    "save": "Submeter pedido",
    "cancel": "Cancelar",
    "procedureCode": "Código do procedimento",
    "notDefined": "Não definido.",
    "subject": {
      "label": "Assunto",
      "required": "Escolha um assunto",
      "invalid": "Escolha um assunto válido"
    },
    "title": {
      "label": "Indique-nos o assunto",
      "required": "Insira um título",
      "invalid": "Insira um título válido"
    },
    "description": {
      "label": "Descreva-nos a situação em que podemos ajudar",
      "required": "Insira uma mensagem",
      "invalid": "Insira uma mensagem válida",
      "help": "Descreva o problema ou dificuldade"
    },
    "files": {
      "label": "Insira ficheiros (opcional)",
      "upload": "Carregar ficheiros"
    },
    "deleteFileConfirm": "Tem a certeza que deseja eliminar o ficheiro?",
    "createdSuccessfully": "Pedido submetido com sucesso!"
  },
  "en": {
    "newTask": "New ticket",
    "thereAreErrors": "There are errors in the form",
    "close": "Close",
    "save": "Submit ticket",
    "cancel": "Cancel",
    "procedureCode": "Procedure Code",
    "notDefined": "Não definido.",
    "subject": {
      "label": "Subject",
      "required": "Choose a subject",
      "invalid": "Choose a valid subject"
    },
    "title": {
      "label": "Subject",
      "required": "Enter a subject",
      "invalid": "Enter a valid subject"
    },
    "description": {
      "label": "Message",
      "required": "Enter a message",
      "invalid": "Enter a valid message",
      "help": "Describe the problem or difficulty"
    },
    "files": {
      "label": "Files",
      "upload": "Upload files"
    },
    "deleteFileConfirm": "Are you sure you want to delete the file?",
    "createdSuccessfully": "Ticket successfully submitted!"
  }
}
</i18n>

<style lang="postcss">
.update-modal-uploader .file-uploads {
  @apply w-full;
}
</style>
