var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"html-editor rounded-md"},[(_vm.editor && !_vm.disabled)?_c('div',{staticClass:"menubar"},[(_vm.formatTools.includes('heading'))?_c('button',{staticClass:"button is-text is-small ml-0",class:{ 'is-active': _vm.editor.isActive('heading', { level: 2 }) },on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleHeading({ level: 2 })
          .run()}}},[_vm._v(" H1 ")]):_vm._e(),(_vm.formatTools.includes('heading'))?_c('button',{staticClass:"button is-text is-small",class:{ 'is-active': _vm.editor.isActive('heading', { level: 3 }) },on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleHeading({ level: 3 })
          .run()}}},[_vm._v(" H2 ")]):_vm._e(),(_vm.formatTools.includes('heading'))?_c('button',{staticClass:"button is-text is-small",class:{ 'is-active': _vm.editor.isActive('heading', { level: 4 }) },on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleHeading({ level: 4 })
          .run()}}},[_vm._v(" H3 ")]):_vm._e(),(_vm.formatTools.includes('bold'))?_c('button',{staticClass:"button is-text is-small",class:{ 'is-active': _vm.editor.isActive('bold') },on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleBold()
          .run()}}},[_c('icon',{attrs:{"name":"bold"}})],1):_vm._e(),(_vm.formatTools.includes('italic'))?_c('button',{staticClass:"button is-text is-small",class:{ 'is-active': _vm.editor.isActive('italic') },on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleItalic()
          .run()}}},[_c('icon',{attrs:{"name":"italic"}})],1):_vm._e(),(_vm.formatTools.includes('underline'))?_c('button',{staticClass:"button is-text is-small",class:{ 'is-active': _vm.editor.isActive('underline') },on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleUnderline()
          .run()}}},[_c('icon',{attrs:{"name":"underline"}})],1):_vm._e(),(false)?_c('button',{staticClass:"button is-text is-small",class:{ 'is-active': _vm.editor.isActive('paragraph') },on:{"click":function($event){return _vm.editor.setParagraph()}}},[_c('icon',{attrs:{"name":"paragraph"}})],1):_vm._e(),(_vm.formatTools.includes('bulletList'))?_c('button',{staticClass:"button is-text is-small",class:{ 'is-active': _vm.editor.isActive('bulletList') },on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleBulletList()
          .run()}}},[_c('icon',{attrs:{"name":"ul"}})],1):_vm._e(),(_vm.formatTools.includes('codeBlock'))?_c('button',{staticClass:"button is-text is-small",class:{ 'is-active': _vm.editor.isActive('codeBlock') },on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleCodeBlock()
          .run()}}},[_c('icon',{attrs:{"name":"code"}})],1):_vm._e(),(_vm.formatTools.includes('link'))?_c('button',{staticClass:"button is-text is-small",on:{"click":function($event){return _vm.setLink()}}},[_c('fw-icon-link',{staticClass:"h-5 w-5"})],1):_vm._e(),(_vm.formatTools.includes('link'))?_c('button',{staticClass:"button is-text is-small",class:{ 'is-active': _vm.editor.isActive('link') },on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .unsetLink()
          .run()}}},[_c('fw-icon-unlink',{staticClass:"h-5 w-5"})],1):_vm._e()]):_vm._e(),_c('div',{class:{
      'overflow-y-scroll': _vm.maxHeight != null,
    },style:({
      maxHeight: _vm.maxHeight + 'px',
    })},[_c('editor-content',{attrs:{"editor":_vm.editor},on:{"focus":function($event){return _vm.emitFocus()}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }