<template>
  <div class="w-full h-full flex">
    <!-- Chat -->
    <div v-if="inParticipantsChatView" class="bg-gray-100" style="width: 28rem">
      <PanelChats
        :chats="chats"
        :chat-meeting-only="true"
        :chat-users="chatUsers"
        :all-chat-users="allChatUsers"
        :chat-active="chatActive"
        :editable="allowChat"
        :allow-downloads="allowChat"
        @load-chat="loadChat"
        @load-main-chat="loadMainChat"
        @unload-chat="unloadChat"
        @set-chat-messages="setChatMessages"
        @delete-chat-message="deleteChatMessage"
      >
      </PanelChats>
    </div>

    <!-- Pods -->
    <div v-if="podsSubmenu && podsLength" class="flex flex-col bg-gray-100 w-80">
      <RecycleScroller
        v-slot="{ item }"
        :items="podsFilterBySearch"
        :item-size="55"
        :buffer="50"
        key-field="id"
        class="scroller flex-1 p-3"
      >
        <button
          class="flex px-2 py-1.5 gap-2 relative items-center w-full text-left group rounded my-1"
          :class="{
            'text-primary bg-white': item.fullscreen,
            'text-gray-700 bg-gray-50 hover:text-gray-800 hover:bg-white': !item.fullscreen,
          }"
          @click="findAndFocusPod(item)"
        >
          <UserAvatar :user="item.user" size="xs" />
          <div class="flex-1 text-sm">
            <v-clamp autoresize :max-lines="1" class="font-bold">{{ item.name }}</v-clamp>
            <div v-if="item.attendee && item.attendee.user" class="text-xs">{{ item.number || item.email }}</div>
          </div>
          <div v-if="withJanus" class="flex items-center justify-center gap-2 ml-2">
            <svg
              v-if="!item.withAudio"
              class="fill-current h-4 text-red-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M16.425 17.839A8.941 8.941 0 0 1 13 18.945V23h-2v-4.055A9.004 9.004 0 0 1 3.055 11H5.07a7.002 7.002 0 0 0 9.87 5.354l-1.551-1.55A5 5 0 0 1 7 10V8.414L1.393 2.808l1.415-1.415 19.799 19.8-1.415 1.414-4.767-4.768zm-7.392-7.392l2.52 2.52a3.002 3.002 0 0 1-2.52-2.52zm10.342 4.713l-1.443-1.442c.509-.81.856-1.73.997-2.718h2.016a8.95 8.95 0 0 1-1.57 4.16zm-2.91-2.909l-1.548-1.548c.054-.226.083-.46.083-.703V6a3 3 0 0 0-5.818-1.032L7.686 3.471A5 5 0 0 1 17 6v4a4.98 4.98 0 0 1-.534 2.251z"
              />
            </svg>
          </div>
        </button>
      </RecycleScroller>
    </div>

    <!-- Zoom, Teams, other... -->
    <div v-if="!withJanus" class="meetings-streams-panel flex-1 h-full text-white">
      <div class="is-flex is-flex-align-center is-flex-justify-center is-height-full">
        <div class="has-text-centered is-width-small text-white">
          <h1 class="is-size-2 is-width-tiny text-white">
            Esta aula está a ser dinamizada na plataforma
            <span class="is-capitalized font-bold">{{ externalVideoApp }}</span>
          </h1>
          <div v-if="external" class="has-margin-top-large">
            <a class="button is-primary" :href="external.description" target="_blank">
              Aceder à sessão no
              <span class="is-capitalized has-margin-small">{{ externalVideoApp }}</span>
              <faicon icon="external-link-alt" size="sm"></faicon>
            </a>
            <p class="text-sm text-gray-500">
              Clique para abrir o endereço (em novo separador)
            </p>
            <p class="has-margin-top-large has-margin is-flex is-flex-align-center">
              <b-input
                class="has-margin-right-small is-flex-grow"
                size="is-small"
                :value="external.description"
              ></b-input>
              <button @click="toClipboardUrl(external.description)">Copiar URL</button>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- The real thing! -->
    <div
      v-else
      class="flex-1 flex flex-col"
      :class="{ 'has-fullscreen-pod relative': withFullscreen, 'h-full': !withFullscreen }"
    >
      <!-- Pods pages -->
      <div
        v-if="numberOfPages > 1"
        class="flex justify-center items-center gap-4 mx-3 my-1"
        :class="{ hidden: !activePods.length || withFullscreen }"
      >
        <button
          v-for="page in numberOfPages"
          :key="page"
          class="text-xs md:text-base rounded-full px-3 py-1 font-semibold"
          :class="{
            'text-white bg-primary': page === activePage,
            'text-gray-300 bg-gray-700': page !== activePage,
          }"
          @click="$emit('change-page', page)"
        >
          {{ page }}
        </button>
      </div>
      <div
        class="flex-1 pods-wrapper"
        :class="{
          hidden: !activePods.length,
          'clear-height': currentPagePods > 1 && currentPagePods <= 4,
          'is-floating': withFullscreen,
          'is-floating-visible': withFullscreen && floatingStreamsVisible && activePods.length > 1 && !isMobile,
        }"
      >
        <div id="meeting-pods" class="meetings-streams-grid pods-grid">
          <BlockRemoteStream
            v-for="pod in activePods"
            :key="pod.id"
            :pod="pod"
            :pods-length="podsLength"
            :attendee="attendee"
            :with-fullscreen="withFullscreen"
            :as-webinar="asWebinar"
            :fill-pod="fillPod"
            @mute-attendee="muteAttendee"
            @kick-attendee="kickAttendee"
            @toggle-fullscreen="toggleFullscreen"
            @toggle-pod-video="togglePodVideo"
            @toggle-promote="togglePromote"
            @go-to-user-chat="goToUserChat"
          />
        </div>
      </div>

      <!-- Empty room -->
      <div v-if="!activePods.length" class="h-full flex flex-col items-center gap-3 text-center">
        <div class="flex-1 flex flex-col gap-4 items-center justify-center">
          <div class="text-gray-600">
            <fw-icon-uc-digital-building class="w-32 h-32 lg:w-56 lg:h-56" />
          </div>
          <div class="text-gray-500 text-base font-medium max-w-xs md:max-w-xl mx-auto">
            Ainda não existem outros participantes na sala além de si.
          </div>
        </div>
        <div
          v-if="featuredNews.length && showNews"
          class="max-w-7xl mx-auto text-left text-gray-300 min-h-80 w-full p-10"
        >
          <div class="text-xl text-gray-600 font-semibold mx-5">Enquanto aguarda...</div>
          <div class="grid grid-cols-2 lg:grid-cols-3 gap-7 m-5">
            <a
              v-for="article in featuredNews"
              :key="article.id"
              target="_blank"
              :href="article.url"
              class="flex gap-3 text-left text-gray-300 items-center opacity-70 hover:opacity-100 hover:text-gray-200"
            >
              <div>
                <img :src="article['thumb']" class="object-cover w-20 h-20 rounded overflow-hidden" />
              </div>
              <div class="flex-1 text-sm font-semibold flex items-center">
                <div>{{ article.title }}</div>
              </div>
            </a>
          </div>
          <div class="text-xs font-medium mx-5 text-gray-600">
            Descubra todas as novidades da UC em
            <a target="_blank" href="https://noticias.uc.pt" class="font-extrabold hover:text-gray-400"
              >noticias.uc.pt</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import ServiceUCPages from '@/fw-modules/fw-core-vue/utilities/services/ServiceUCPages'
import { RecycleScroller } from 'vue-virtual-scroller'
import BlockRemoteStream from '@/fw-modules/fw-meetings-vue/components/blocks/BlockRemoteStream'
import UserAvatar from '@/fw-modules/fw-core-vue/id/components/user/UserAvatar'
import PanelChats from '@/fw-modules/fw-core-vue/chats/components/panels/PanelChats'

export default {
  components: {
    BlockRemoteStream,
    UserAvatar,
    RecycleScroller,
    PanelChats,
  },

  props: {
    allowChat: {
      type: Boolean,
      default: true,
    },
    view: {
      type: String,
      default: 'participants',
    },
    external: {
      type: Object,
      default: null,
    },
    asWebinar: Boolean,
    chats: {
      type: Array,
      deep: true,
    },
    showNews: {
      type: Boolean,
      default: true,
    },
    pods: Array,
    activePods: Array,
    podsLength: Number,
    numberOfPages: Number,
    activePage: Number,
    currentPagePods: Number,
    attendee: Object,
    withFullscreen: Boolean,
    floatingStreamsVisible: Boolean,
    podsSubmenu: Boolean,
    chatSubmenu: Boolean,
    allChatUsers: Object,
    chatUsers: Array,
    chatActive: Object,
    withJanus: Boolean,
    fillPod: Boolean,
  },

  data() {
    return {
      searchQuery: null,
      featuredNews: [],
    }
  },

  computed: {
    isMobile() {
      return window.innerWidth < 640
    },
    inParticipantsChatView() {
      // Should match ViewLive.isInParticipantsChatView
      return this.view === 'participants' && this.chatSubmenu
    },
    podsSorted() {
      return this.pods
        .filter(a => {
          return !!a.attendee
        })
        .sort((a, b) => {
          return a.name.localeCompare(b.name)
        })
    },
    podsFilterBySearch() {
      if (this.searchQuery) {
        const find = utils.existsInSearchFactory(this.searchQuery)
        return this.podsSorted.filter(pod => {
          return find(pod.name)
        })
      }
      return this.podsSorted
    },
    externalVideoApp() {
      if (this.external && this.external.description) {
        const app = utils.getExternalVideoAppFromUrl(this.external.description)
        if (app) {
          return app
        }
      }

      return 'unknown'
    },
    loggedUser() {
      return this.$store.getters.getUser
    },
  },

  watch: {
    searchQuery() {}, // KEEP IT! We need to watch it to update the value
  },

  created() {
    if (!this.isMobile && this.showNews) {
      setTimeout(() => {
        this.getNews()
      }, 1750)
    }
  },

  methods: {
    async getNews() {
      const data = await ServiceUCPages.getNewsFeatured()
      this.featuredNews = data.articles.slice(0, 6)
    },

    togglePodVideo(pod) {
      if (pod.video) this.$emit('enable-stream', pod.video, !pod.video.enabled)
    },
    toogleAllPodVideo() {
      this.pods.forEach(pod => {
        this.togglePodVideo(pod)
      })
    },
    toggleFullscreen(pod) {
      this.$emit('toggle-fullscreen', pod)
    },
    toggleFloatingStreamsVisibility() {
      this.$emit('toggle-floating-streams-visibility')
    },
    muteAttendee(attendee) {
      this.$emit('mute-attendee', attendee)
    },
    kickAttendee(attendee) {
      this.$emit('kick-attendee', attendee)
    },
    togglePromote(attendee, noModal, promote) {
      this.$emit('toggle-promote', attendee, noModal, promote)
    },
    loadChat(chat, quietly = false, force = false, callback = null) {
      this.$emit('load-chat', chat, quietly, force, callback)
    },
    loadMainChat(callback) {
      this.$emit('load-main-chat', callback)
    },
    unloadChat(key) {
      this.$emit('unload-chat', key)
    },
    async setChatMessages(chatKey, messages, fromStart = false, toBottom = false) {
      await this.$emit('set-chat-messages', chatKey, messages, fromStart, toBottom)
    },
    deleteChatMessage(chatKey, key) {
      this.$emit('delete-chat-message', chatKey, key)
    },
    findAndFocusPod(pod) {
      if (this.withJanus && !pod.fullscreen) this.$emit('find-and-focus-pod', pod)
    },
    toClipboardUrl(url) {
      this.$copyText(url).then(() => {
        this.$buefy.toast.open({
          duration: 3000,
          message: `Endereço copiado`,
          position: 'is-top',
          type: 'is-primary',
        })
      })
    },
    goToUserChat(user) {
      this.$emit('go-to-user-chat', user)
    },
  },
}
</script>

<style lang="scss" scoped>
.pods-wrapper {
  .pods-grid {
    @apply gap-2 h-full;
  }

  &.is-floating {
    @apply flex justify-end items-end;

    .pods-grid {
      @apply flex items-center h-auto overflow-x-auto max-w-full m-0 flex-nowrap;

      .remote-pod {
        &:not(.fullscreen-on) {
          @apply w-32 h-24 invisible z-0 my-2 mx-1;
        }
      }
    }

    &.is-floating-visible {
      .pods-grid {
        .remote-pod {
          &:not(.fullscreen-on) {
            @apply visible z-0;
          }
        }
      }
    }
  }

  &.is-floating-visible {
    .pods-grid {
      .remote-pod {
        &.fullscreen-on {
          height: calc(100% - 98px);
        }
      }
    }
  }
}
</style>
