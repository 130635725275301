<template>
  <b-modal
    :active="true"
    :on-cancel="closeModal"
    has-modal-card
    aria-role="dialog"
    aria-modal
    class="meetings-modal-pods-enter-queue"
  >
    <div v-if="waitingAttendees.length" class="modal-card is-width-auto has-text-centered">
      <section class="modal-card-body">
        <div
          v-if="waitingAttendees.length > 1"
          class="has-margin-bottom-medium is-flex is-flex-right is-flex-align-center"
        >
          <b-button type="is-text" @click="rejectAll">Rejeitar todos</b-button>
          <b-button
            v-if="notAnonymousWaitingAttendees.length > 1"
            class="has-margin-left"
            type="is-primary"
            outlined
            @click="acceptAll"
          >
            Aceitar todos
          </b-button>
        </div>
        <div class="columns is-multiline is-flex is-flex-justify-center">
          <div
            v-for="attendee in waitingAttendees"
            :key="attendee.key"
            class="column has-background-light is-rounded is-auto has-margin"
          >
            <div class="flex items-center justify-center">
              <UserAvatar size="md" :user="attendee.user" />
            </div>
            <div v-if="!attendee.user.key" class="has-margin-top has-text-tiny has-text-weight-bold text-center">
              Convidado
            </div>
            <div class="has-margin-top">
              <h1 class="text-base font-bold">{{ attendee.user.name }}</h1>
            </div>
            <div class="text-sm text-gray-500">está a solicitar autorização para entrar.</div>
            <div class="has-margin-top-medium">
              <b-button
                icon-left="check"
                aria-label="Aceitar participante"
                type="is-primary"
                outlined
                class="has-margin-right"
                @click="$emit('accept-participants', [attendee.key])"
              ></b-button>
              <b-button
                aria-label="Colocar em espera"
                class="has-margin-right"
                icon-left="minus"
                type="is-dark"
                outlined
                @click="closeModal"
              ></b-button>

              <b-button
                aria-label="Rejeitar participante"
                icon-left="times"
                outlined
                type="is-danger"
                @click="rejectOne(attendee.key)"
              ></b-button>
            </div>
          </div>
        </div>
      </section>
    </div>
  </b-modal>
</template>

<script>
import UserAvatar from '@/fw-modules/fw-core-vue/id/components/user/UserAvatar'

export default {
  components: {
    UserAvatar,
  },

  props: {
    waitingAttendees: {
      type: Array,
    },
  },

  computed: {
    notAnonymousWaitingAttendees() {
      return this.waitingAttendees.filter(attendee => !!attendee.user.key)
    },
  },

  methods: {
    closeModal() {
      this.$emit('close')
    },

    acceptAll() {
      const keys = this.notAnonymousWaitingAttendees.map(i => i.key)
      if (keys.length) {
        this.$emit('accept-participants', keys)
      }
    },
    rejectAll() {
      this.$emit('open-remove-attendee-modal', {
        action: 'reject',
        multiple: true,
        message: '<h2>Rejeitar todos os participantes em espera?</h2>',
        buttonMessage: 'Rejeitar todos',
        callback: async data => {
          const keys = this.waitingAttendees.map(i => i.key)
          if (keys.length) {
            this.$emit('reject-participants', keys, data)
          }
        },
      })
    },
    rejectOne(userKey) {
      this.$emit('open-remove-attendee-modal', {
        action: 'reject',
        message: '<h2>Rejeitar o participante?</h2>',
        buttonMessage: 'Rejeitar',
        callback: async data => {
          this.$emit('reject-participants', [userKey], data)
        },
      })
    },
  },
}
</script>
