<template>
  <fw-layout mobile-ready management :back-to-enable="false" extended-logo>
    <template #main-content>
      <PanelUCIdHero :user="user" class="mt-4 mb-10" management />
      <fw-panel :title="$t('spaces')" featured class="my-5">
        <template v-if="canCreateSpace" #toolbar>
          <span class="flex flex-1 justify-end">
            <fw-button type="transparent-primary" @click.native="isModalActive = true">{{ $t('add') }}</fw-button></span
          >
        </template>

        <PanelSpaces :show-filters="false" @searching="hideStatsOnSearching = true">
          <!-- <template v-if="!hideStatsOnSearching" #stats>
          <div class="flex gap-10 my-5 ml-5">
            <PanelStats :title="'Números bonitos'" :stats="stats" :loading="loadingStats" />
          </div>
        </template> -->
        </PanelSpaces>
      </fw-panel>

      <fw-modal :active.sync="isModalActive" :can-cancel="true" @close="closeModal">
        <ModalCreateSpace :saving-data="loading" @close="closeModal" @save="createSpace" />
      </fw-modal>

      <fw-panel-info label="Permissions (raw)" debug>
        <json-viewer :value="{ permissions: userPermissions, canCreateSpace }"></json-viewer>
      </fw-panel-info>
    </template>
  </fw-layout>
</template>

<script>
import PanelUCIdHero from '@/fw-modules/fw-core-vue/id/components/panels/PanelUCIdHero'
import PanelSpaces from '@/components/panels/PanelSpaces'
import ModalCreateSpace from '@/components/modals/ModalCreateSpace'
// import PanelStats from '@/fw-modules/fw-core-vue/ui/components/panels/PanelStats'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    PanelSpaces,
    ModalCreateSpace,
    PanelUCIdHero,
    // PanelStats,
  },

  data() {
    return {
      hideStatsOnSearching: false,
      loadingStats: false,
      loading: false,
      stats: [
        {
          label: '',
          value: 10,
          valueSuffix: null,
          description: 'Total',
        },
        {
          label: '',
          value: 3,
          valueSuffix: null,
          description: 'Coisas',
        },
        {
          label: '',
          value: 7,
          valueSuffix: null,
          description: 'Coisitas',
        },
      ],
      isModalActive: false,
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    user() {
      return this.$store.getters.getUser
    },

    userPermissions() {
      return this.$store.getters.getUserPermissions
    },

    canCreateSpace() {
      return this.user?.roles.includes('uctasks-manager') || this.user?.roles.includes('uctasks-admin')
    },
  },

  methods: {
    closeModal() {
      this.isModalActive = false
    },

    async createSpace(spaceData) {
      console.log('createSpace :>> ', spaceData)
      try {
        const result = await this.api.createSpace(spaceData)
        this.loading = false
        this.$router.push({ name: 'manage-space', params: { key: result.key } })
      } catch (error) {
        console.error(error)
        const errorKey = utils.errors(error).getKey()
        console.log('Error KEY', errorKey)
        if (errorKey && errorKey == 'SpacePrefixAlreadyExists') {
          this.$buefy.dialog.alert({
            title: this.$t('spacePrefixAlreadyExists.title'),
            message: this.$t('spacePrefixAlreadyExists.message'),
            type: 'is-danger',
          })
        } else {
          this.$buefy.snackbar.open({
            title: this.$t('errorOccurred.title'),
            message: this.$t('errorOccurred.message'),
            type: 'is-danger',
          })
        }

        this.loading = false
        this.loadingError = true
      }
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "spaces": "Espaços",
    "add": "Adicionar",
    "spacePrefixAlreadyExists": {
      "title": "Prefixo já definido",
      "message": "Já existe um espaço com o prefixo que tentou definir. Por favor, indique outro para guardar as alterações."
    },
    "errorOccurred": {
      "title": "Ocorreu um erro",
      "message": "Ocorreu um erro não esperado ao guardar o espaço. Por favor, contacte a nossa equipa de suporte."
    }
  },
  "en": {
    "spaces": "Spaces",
    "add": "Add",
    "spacePrefixAlreadyExists": {
      "title": "Prefix already set",
      "message": "There is already a space with the prefix you tried to set. Please specify another one to save your changes."
    },
    "errorOccurred": {
      "title": "An error has occurred",
      "message": "An unexpected error occurred while saving the space. Please contact our support team."
    }
  }
}
</i18n>
