var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative"},[_c('div',{staticClass:"flex flex-col"},[_c('fw-panel',{attrs:{"title":_vm.$t('newSpace'),"featured":"","loading":_vm.savingData,"after-loading-checked":""}}),_c('div',{staticClass:"my-2"},[_c('fw-label',[_vm._v(_vm._s(_vm.$t('type.label')))]),_c('b-select',{model:{value:(_vm.space.type),callback:function ($$v) {_vm.$set(_vm.space, "type", $$v)},expression:"space.type"}},_vm._l((_vm.availableTypes),function(option){return _c('option',{key:option,domProps:{"value":option}},[_vm._v(" "+_vm._s(_vm.$t(`spaceTypes.${option}`))+" ")])}),0)],1),_c('div',{staticClass:"mb-2"},[_c('fw-label',[_vm._v(_vm._s(_vm.$t('prefix.label')))]),_c('TextInput',{class:{
          error: _vm.$v.space.prefix.$error,
        },attrs:{"minlength":1,"maxlength":10,"placeholder":_vm.$t('prefix.placeholder'),"help":_vm.$t('charsLimit', { limit: '10' }),"uppercase":""},model:{value:(_vm.space.prefix),callback:function ($$v) {_vm.$set(_vm.space, "prefix", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"space.prefix"}}),(_vm.$v.space.prefix.$error)?_c('fw-tip',{attrs:{"error":""}},[(!_vm.$v.space.prefix.required)?_c('span',[_vm._v(_vm._s(_vm.$t('prefix.required')))]):(!_vm.$v.space.prefix.alphanumeric)?_c('span',[_vm._v(_vm._s(_vm.$t('prefix.alphanumeric')))]):_c('span',[_vm._v(_vm._s(_vm.$t('prefix.invalid')))])]):_vm._e()],1),_c('div',{staticClass:"mb-2"},[_c('fw-label',[_vm._v(_vm._s(_vm.$t('title.label')))]),_c('TextInput',{class:{
          error: _vm.$v.space.title.$error,
        },attrs:{"minlength":1,"maxlength":250,"placeholder":_vm.$t('title.placeholder')},model:{value:(_vm.space.title),callback:function ($$v) {_vm.$set(_vm.space, "title", $$v)},expression:"space.title"}}),(_vm.$v.space.title.$error)?_c('fw-tip',{attrs:{"error":""}},[(!_vm.$v.space.title.required)?_c('span',[_vm._v(_vm._s(_vm.$t('title.required')))]):_c('span',[_vm._v(_vm._s(_vm.$t('title.invalid')))])]):_vm._e()],1),_c('div',{staticClass:"mb-2"},[_c('fw-label',[_vm._v(_vm._s(_vm.$t('teamName.label')))]),_c('TextInput',{class:{
          error: _vm.$v.space.team_name.$error,
        },attrs:{"minlength":2,"maxlength":50,"placeholder":_vm.$t('teamName.placeholder')},model:{value:(_vm.space.team_name),callback:function ($$v) {_vm.$set(_vm.space, "team_name", $$v)},expression:"space.team_name"}}),(_vm.$v.space.team_name.$error)?_c('fw-tip',{attrs:{"error":""}},[(!_vm.$v.space.team_name.required)?_c('span',[_vm._v(_vm._s(_vm.$t('teamName.required')))]):_c('span',[_vm._v(_vm._s(_vm.$t('teamName.invalid')))])]):_vm._e()],1),_c('div',{staticClass:"flex items-center justify-end gap-5"},[_c('fw-button',{attrs:{"type":"link-muted"},nativeOn:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('fw-button',{attrs:{"type":"primary"},nativeOn:{"click":function($event){return _vm.saveSpace.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }