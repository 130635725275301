import ViewManageHome from '@/views/manage/ViewManageHome'

export default [
  {
    path: '/manage',
    name: 'manage-home',
    component: ViewManageHome,
    meta: { requiresAuth: true },
  },
  {
    path: '/manage/spaces/:key',
    name: 'manage-space',
    component: () => import('@/views/manage/ViewManageSpace'),
    meta: { requiresAuth: true, view: 'dashboard' },
  },
  {
    path: '/manage/spaces/:key/queues/:queueKey/tasks',
    name: 'manage-tasks',
    component: () => import('@/views/manage/ViewManageSpaceTasks'),
    meta: { requiresAuth: true },
  },
  {
    path: '/manage/spaces/:key/queues',
    name: 'manage-space-queues',
    component: () => import('@/views/manage/ViewManageSpace'),
    meta: { requiresAuth: true, view: 'queues' },
  },
  {
    path: '/manage/spaces/:key/queues/:queueKey',
    name: 'manage-space-queue',
    component: () => import('@/views/manage/ViewManageSpace'),
    meta: { requiresAuth: true, view: 'queue-tasks' },
  },
  {
    path: '/manage/spaces/:key/queues/:queueKey/tasks/:taskId',
    name: 'manage-space-queue-task',
    component: () => import('@/views/manage/ViewManageSpace'),
    meta: { requiresAuth: true, view: 'queue-task' },
  },
  {
    path: '/manage/spaces/:key/people',
    name: 'manage-space-people',
    component: () => import('@/views/manage/ViewManageSpace'),
    meta: { requiresAuth: true, view: 'people' },
  },
  {
    path: '/manage/spaces/:key/activity',
    name: 'manage-space-activity',
    component: () => import('@/views/manage/ViewManageSpace'),
    meta: { requiresAuth: true, view: 'activity' },
  },
  {
    path: '/manage/spaces/:key/notifications',
    name: 'manage-space-notifications',
    component: () => import('@/views/manage/ViewManageSpace'),
    meta: { requiresAuth: true, view: 'notifications' },
  },
  {
    path: '/manage/spaces/:key/settings',
    name: 'manage-space-settings',
    component: () => import('@/views/manage/ViewManageSpace'),
    meta: { requiresAuth: true, view: 'settings' },
  },
]
