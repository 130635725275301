<template>
  <div
    class="meetings-handsup-sidebar bg-primary bg-opacity-80 rounded-lg"
    :class="{ 'level-up': Boolean(waitingAttendees.length) }"
  >
    <div v-for="attendeeKey in attendeesHandsUp.slice(0, 5)" :key="attendeeKey">
      <div v-if="attendees[attendeeKey].user" class="w-full px-3 py-2 flex gap-2 items-center justify-between text-sm">
        <div class="flex items-center gap-3 text-white font-bold">
          <Avatar :user="attendees[attendeeKey].user" :hand-raised="true" />
          <v-clamp autoresize :max-lines="2">{{ getAttendeeName(attendeeKey) }}</v-clamp>
        </div>
        <div>
          <b-dropdown :triggers="['click']" aria-role="list" position="is-top-left" animation="none">
            <button slot="trigger" class="button text-white">
              <b-icon icon="ellipsis-h"></b-icon>
            </button>
            <b-dropdown-item v-if="withJanus" aria-role="listitem">
              <b-button
                class="is-flex-start"
                expanded
                size="is-small"
                type="is-text"
                icon-left="thumbtack"
                @click="$emit('find-and-focus-pod', attendees[attendeeKey].pod)"
                >Fixar</b-button
              >
            </b-dropdown-item>
            <b-dropdown-item v-if="withJanus && asWebinar && canPromote" aria-role="listitem">
              <b-button
                v-if="attendees[attendeeKey].withRole('show_to_everyone')"
                class="is-flex-start"
                expanded
                size="is-small"
                type="is-text"
                icon-left="crown"
                @click="togglePromote(attendeeKey, false)"
                >Despromover (orador)</b-button
              >
              <b-button
                v-else
                class="is-flex-start"
                expanded
                size="is-small"
                type="is-text"
                icon-left="crown"
                @click="togglePromote(attendeeKey, true)"
                >Promover a orador</b-button
              >
            </b-dropdown-item>
            <b-dropdown-item
              v-if="withJanus && canPromote && attendees[attendeeKey].canBePromoted()"
              aria-role="listitem"
            >
              <b-button
                class="is-flex-start"
                expanded
                size="is-small"
                type="is-text"
                icon-left="crown"
                @click="$emit('toggle-promote', attendees[attendeeKey])"
                >Permissões</b-button
              >
            </b-dropdown-item>
            <b-dropdown-item v-if="canLowerHands" aria-role="listitem">
              <b-button
                class="is-flex-start"
                expanded
                size="is-small"
                type="is-text"
                icon-left="hand-paper"
                @click="$emit('lower-attendee-hand', attendees[attendeeKey])"
                >Baixar a mão</b-button
              >
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
    <div v-if="attendeesHandsUp.length > 5" class="text-right p-2 text-sm text-white">
      <div>E mais {{ attendeesHandsUp.length - 5 }} à espera...</div>
    </div>
  </div>
</template>

<script>
import Avatar from '@/fw-modules/fw-core-vue/ui/components/users/Avatar'

export default {
  components: {
    Avatar,
  },

  props: {
    asWebinar: Boolean,
    attendee: Object,
    attendeesHandsUp: Array,
    attendees: Object,
    withJanus: Boolean,
    waitingAttendees: Array,
  },

  computed: {
    canPromote() {
      return this.attendee.withRole('can_promote')
    },
    canLowerHands() {
      return this.attendee.withRole('can_lower_hands')
    },
  },

  methods: {
    getAttendeeName(key) {
      const attendee = this.attendees[key]
      if (attendee.name) {
        return attendee.name
      } else if (attendee.user) {
        return attendee.user.name
      } else {
        return attendee.key
      }
    },
    togglePromote(attendeeKey, promote) {
      const attendee = this.attendees[attendeeKey]
      this.$emit('toggle-promote', attendee, true, promote)
    },
  },
}
</script>
