<template>
  <a
    class="relative rounded-lg px-3 py-2.5 hover:bg-gray-200 hover:bg-opacity-50 group w-full flex items-center text-left gap-2"
    :href="openSpaceUrl"
  >
    <div class="flex-1">
      <div class="flex-shrink-0 font-medium flex items-center gap-2">
        <fw-icon-compass class="w-5 h-5 text-gray-500" />
        <span class="text-gray-500">{{ space.prefix | uppercase }}</span>
        <v-clamp autoresize :max-lines="1" class=" font-semibold ">
          {{ space.title }}
        </v-clamp>
      </div>
    </div>

    <div class="w-20 flex items-center text-gray-500 text-bold text-sm">
      {{ $t(`spaceType.${space.type}`) }}
    </div>

    <div class="flex gap-2">
      <fw-tag :type="stats?.new ? 'primary' : 'xlight'" :counter="stats?.new ?? '0'">Novas</fw-tag>
      <fw-tag :type="stats?.new ? 'light-primary' : 'xlight'" :counter="stats?.open ?? '0'">Abertas</fw-tag>
      <fw-tag type="xlight" :counter="stats?.closed ?? '0'">Fechadas</fw-tag>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
    },
    language: {
      type: String,
      default: 'pt',
    },
    space: {
      type: Object,
    },
    stats: {
      type: [Object, undefined],
    },
  },

  computed: {
    openSpaceUrl() {
      return this.$router.resolve({
        name: 'manage-space',
        params: {
          key: this.space.key,
        },
      }).href
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "createdAt": "Criado em",
    "spaceType": {
      "support": "Suporte",
      "development": "Desenvolvimento"
    }
  },
  "en": {
    "createdAt": "Created at",
    "spaceType": {
      "support": "Support",
      "development": "Development"
    }
  }
}
</i18n>
