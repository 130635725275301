<template>
  <b-dropdown aria-role="list" position="is-bottom-left">
    <fw-button-dropdown
      slot="trigger"
      aria-role="listitem"
      type="transparent"
      icon="more"
      :size="size"
      :chevron="false"
    />
    <slot name="default" />
  </b-dropdown>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: 'sm',
    },
  },

  data() {
    return {}
  },
}
</script>
