<template>
  <b-modal :active="true" :on-cancel="close" has-modal-card aria-role="dialog" aria-modal>
    <div class="modal-card">
      <section class="modal-card-body is-width-small">
        <div class="has-margin-bottom-small is-flex is-flex-align-center" v-html="data.message"></div>
        <div v-if="data.action == 'reject' && data.multiple && askRejectMessage" class="has-text-muted">
          Deixe uma mensagem personalizada (opcional) aos participantes, indicando que não poderá autorizar acesso à
          sessão ou que devem voltar a tentar mais tarde.
        </div>
        <div v-else-if="data.action == 'reject' && askRejectMessage" class="has-text-muted">
          Deixe uma mensagem personalizada (opcional) ao participante, indicando que não poderá autorizar acesso à
          sessão ou que deve voltar a tentar mais tarde.
        </div>
        <div v-else-if="askRejectMessage" class="has-text-muted">
          Deixe uma mensagem personalizada (opcional) ao participante, indicando a razão pela qual está a ser removido
          da sessão.
        </div>
        <div v-if="askRejectMessage" class="has-margin-top">
          <div class="label">Mensagem para o participante</div>
          <b-input v-model="userMessage" type="textarea" maxlength="200" expanded placeholder="Sem mensagem"></b-input>
        </div>
        <div>
          <div class="is-flex is-flex-align-center">
            <b-checkbox v-model="ban">Bloquear acesso a esta sessão</b-checkbox>
            <b-button
              type="is-text"
              aria-label="Mais info"
              class="has-margin-left-small"
              icon-left="question-circle"
              @click="infoBoxes.ban = !infoBoxes.ban"
            ></b-button>
          </div>
          <div v-if="infoBoxes.ban" class="has-margin-top-small has-margin-bottom has-text-muted has-text-small">
            Ao bloquear o acesso, o participante não poderá voltar a pedir autorização para entrar na sala, até terminar
            esta sessão.
          </div>
        </div>
        <div v-if="data.action == 'kick' && isExam" class="has-margin-bottom-medium">
          <b-checkbox v-model="extendToExam">Aplicar a todos as salas do exame</b-checkbox>
        </div>
        <div class="has-margin-top is-flex is-flex-right is-flex-align-center">
          <b-button type="is-text" @click="close">Cancelar</b-button>
          <b-button class="has-margin-left" type="is-danger" outlined @click="call">{{ data.buttonMessage }}</b-button>
        </div>
      </section>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    data: Object,
    isExam: Boolean,
    askRejectMessage: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      ban: false,
      extendToExam: false,
      userMessage: '',
      infoBoxes: {
        ban: false,
      },
    }
  },

  methods: {
    close() {
      this.$emit('close')
    },
    call() {
      this.$emit('call', { message: this.userMessage, ban: this.ban, extendToExam: this.extendToExam })
    },
  },
}
</script>
