<template>
  <b-modal
    :active.sync="isActive"
    :on-cancel="closeModal"
    has-modal-card
    aria-role="dialog"
    aria-modal
    class="meetings-modal-pods-enter-queue"
  >
    <div class="modal-card is-width-small">
      <section class="modal-card-body">
        <div class="is-flex is-flex-space-between">
          <div class="is-flex is-flex-align-center">
            <div class="label is-size-3">Microfone bloqueado</div>
          </div>
          <div>
            <b-button type="is-text" icon-right="times" @click="closeModal">Fechar</b-button>
          </div>
        </div>
        <div class="is-size-4 has-margin-bottom mt-4">
          O seu microfone está bloqueado. Para falar, por favor, levante a mão <faicon icon="hand-paper" size="lg" /> e
          aguarde que um dos oradores da sessão autorize a sua intervenção e desbloqueie o seu microfone.
        </div>
        <div class="mt-2 text-sm">
          O seu microfone poderá <strong>apenas ser ativado por si</strong>, após ter sido desbloqueado.
        </div>
      </section>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    showAudioLockedModal: Boolean,
  },

  data() {
    return {
      isActive: this.showAudioLockedModal,
    }
  },

  watch: {
    showAudioLockedModal(value) {
      this.isActive = value
    },
  },

  methods: {
    closeModal() {
      this.$emit('close-modal')
      this.$emit('close', false)
    },
  },
}
</script>
