<template>
  <BlockUserDetails :user="user" :loading="loading">
    <fw-panel
      v-if="queues && queues.length"
      :title="$t('queues')"
      :loading="savingData"
      after-loading-checked
      boxed="sm"
      custom-class="bg-gray-100"
    >
      <fw-label marginless>{{ $t(`role.${user.role}`) }}:</fw-label>
      <div class="flex flex-col gap-1 text-sm pb-5 lg:pb-7">
        <div class="flex gap-2 items-center flex-wrap">
          <b-checkbox
            v-for="queue in sortedQueues"
            :key="queue.key"
            v-model="selectedQueues"
            :native-value="queue.key"
            class="pt-2"
            @input="saveQueues"
          >
            {{ queue.prefix | uppercase }}
          </b-checkbox>
        </div>

        <fw-tip v-if="$v.selectedQueues.$error" error>
          {{ $t('chooseAtLeastOneQueue') }}
        </fw-tip>
      </div>
    </fw-panel>
  </BlockUserDetails>
</template>

<script>
import BlockUserDetails from '@/fw-modules/fw-core-vue/id/components/blocks/BlockUserDetails'
import { required, minLength } from 'vuelidate/lib/validators'

export default {
  name: 'UserDetails',
  components: { BlockUserDetails },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    savingData: {
      type: Boolean,
      default: false,
    },
    queues: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: true,
      selectedQueues: [],
    }
  },

  validations: {
    selectedQueues: { required, min: minLength(1) },
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    loggedUser() {
      return this.$store.getters.getUser
    },

    sortedQueues() {
      return this.queues.toSorted((a, b) => a.prefix.localeCompare(b.prefix))
    },
  },

  mounted() {
    this.selectedQueues = this.user.queueKeys
  },

  methods: {
    saveQueues() {
      this.$emit('update-queues', {
        users: [this.user.key],
        queues: this.selectedQueues,
        refresh: false,
      })
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "queues": "Filas",
    "noQueues": "Sem filas atribuídas.",
    "role": {
      "space-admin": "Gestor de espaço",
      "queue-admin": "Gestor da(s) fila(s)",
      "queue-worker": "Trabalhador da(s) fila(s)"
    },
    "chooseAtLeastOneQueue": "Escolha pelo menos uma fila"
  },
  "en": {
    "queues": "Queues",
    "noQueues": "No queues assigned.",
    "role": {
      "space-admin": "Space manager",
      "queue-admin": "Queue manager",
      "queue-worker": "Queue worker"
    },
    "chooseAtLeastOneQueue": "Choose at least one queue"
  }
}
</i18n>
