/* eslint-disable no-unused-vars */

// Use NODE_ENV as 'production' or 'development' to set the environment
// Node and friends need it to export files correctly
// Staging should emulate production: use production as NODE_ENV
const fwRealEnv = process.env.VUE_APP_ENV || process.env.NODE_ENV
const fwAppKey = process.env.VUE_APP_KEY

const INSTITUTIONAL_DOMAINS = [
  'adm.uc.pt',
  'alumni.fd.uc.pt',
  'auc.uc.pt',
  'bcs.uc.pt',
  'bg.uc.pt',
  'brainimaging.pt',
  'casadalusofonia.uc.pt',
  'cienciasemfronteiras.it',
  'cnc.uc.pt',
  'colegiodasartes.uc.pt',
  'csf.ccisp.pt',
  'darq.uc.pt',
  'dct.uc.pt',
  'dec.uc.pt',
  'dem.uc.pt',
  'efs.uc.pt',
  'fcdef.uc.pt',
  'fct.uc.pt',
  'fd.uc.pt',
  'fe.uc.pt',
  'ff.uc.pt',
  'fis.uc.pt',
  'fl.uc.pt',
  'fmed.uc.pt',
  'fpce.uc.pt',
  'gian.fis.uc.pt',
  'iav.uc.pt',
  'icnas.uc.pt',
  'ij.uc.pt',
  'itecons.uc.pt',
  'museudaciencia.com',
  'museudaciencia.org',
  'museudaciencia.pt',
  'museudaciencia.uc.pt',
  'pet.uc.pt',
  'qui.uc.pt',
  'sas.uc.pt',
  'saturno.fis.uc.pt',
  'sib.uc.pt',
  'student.darq.uc.pt',
  'student.dct.uc.pt',
  'student.dec.uc.pt',
  'student.dem.uc.pt',
  'student.fcdef.uc.pt',
  'student.fct.uc.pt',
  'student.fe.uc.pt',
  'student.ff.uc.pt',
  'student.fis.uc.pt',
  'student.fl.uc.pt',
  'student.fmed.uc.pt',
  'student.fpce.uc.pt',
  'student.qui.uc.pt',
  'student.uc.pt',
  'tagv.uc.pt',
  'uc.pt',
]

const APPS_USING_UCID_SSO = ['ucdigitaldesk---disabled']

export const FW_APPS = [
  {
    key: 'myuc',
    title: 'MyUC',
    quickActions: ['create-support-tasks'],
  },
  {
    key: 'ucstudent',
    title: 'UC Student',
    quickActions: ['create-support-tasks'],
  },
  {
    key: 'ucteacher',
    title: 'UC Teacher',
    quickActions: ['create-support-tasks'],
  },
  {
    key: 'ucmeetings',
    title: 'UC Meetings',
    quickActions: ['create-support-tasks'],
  },
  {
    key: 'ucsocialsupport',
    title: 'UC SocialSupport',
    quickActions: ['create-support-tasks'],
  },
  {
    key: 'uccompetitions',
    title: 'UC Competitions',
    quickActions: ['create-support-tasks'],
  },
  {
    key: 'uctasks',
    title: 'UC Tasks',
    quickActions: ['create-support-tasks'],
  },
  {
    key: 'ucapply',
    title: 'UC Apply',
    quickActions: ['create-support-tasks'],
  },
]

// Get the current app config based on the VUE_APP_KEY
const getCurrentAppConfig = () => {
  return FW_APPS.find(app => app.key === fwAppKey)
}

// Main configuration object for current app
const envConfig = {
  local: {
    appMeta: getCurrentAppConfig(),
    wsUrl: 'wss://fw.dev.ucframework.pt',
    apiUrlIduc: 'https://id.fw.dev.ucframework.pt',
    apiUrlMeetings: 'https://meetings.fw.dev.ucframework.pt',
    apiUrlAcademic: 'https://academic.fw.dev.ucframework.pt',
    apiUrlChat: 'https://chat.fw.dev.ucframework.pt',
    apiUrlNotes: 'https://notes.fw.dev.ucframework.pt',
    apiUrlStorage: 'https://storage.fw.dev.ucframework.pt',
    apiUrlExams: 'https://exams.fw.dev.ucframework.pt',
    apiUrlForms: 'https://forms.fw.dev.ucframework.pt',
    apiUrlBuckets: 'https://buckets.fw.dev.ucframework.pt',
    apiUrlNotifications: 'https://notifications.fw.dev.ucframework.pt',
    apiUrlSpaces: 'https://spaces.fw.dev.ucframework.pt',
    apiUrlQflow: 'https://qflow.fw.dev.ucframework.pt',
    apiUrlPages: 'https://ucpages.uc.pt',
    apiUrlApply: 'http://localhost:8011/api',
    apiUrlTasks: 'http://localhost:8015',
    apiUrlActivity: 'https://activity.fw.dev.ucframework.pt',
    apiUrlMyUC: 'http://localhost:8018',
    apiUrlSocialSupport: 'http://localhost:8026',
    appUrlOne: 'https://one.dev.ucframework.pt',
    appUrlUCId: 'http://localhost:9000',
    ucpluginUrl: 'https://plug.dev.ucframework.pt',
    ucmeetingsUrl: 'https://ucmeetings.dev.ucframework.pt',
    ucpreviewUrl: 'https://preview.dev.ucframework.pt',
    apiUrlContent: 'https://content.fw.dev.ucframework.pt',
    apiUrlCompetitions: 'https://competitions.fw.dev.ucframework.pt/',
    apiUrlCourses: 'https://courses.fw.dev.ucframework.pt',
    // secureDomains are used to protect the user in case of wrong redirect in the notifications
    secureDomains: ['uc.pt', 'ucframework.pt'],
    semesterStart: ['09-01', '02-06'], // Start dates of semester: [1st Semester, 2nd Semester] format: MM-DD
    institutionalDomains: INSTITUTIONAL_DOMAINS,
    allowExternalAccountsRegister: process.env.VUE_APP_EXTERNAL_ACCOUNTS_REGISTER === 'true' ? true : false,
    allowExternalAccountsLogin: process.env.VUE_APP_EXTERNAL_ACCOUNTS_LOGIN === 'true' ? true : false,
    appsUCIdSSO: APPS_USING_UCID_SSO,
  },
  alllocal: {
    appMeta: getCurrentAppConfig(),
    wsUrl: 'ws://localhost:8020',
    apiUrlIduc: 'http://localhost:8002',
    apiUrlMeetings: 'http://localhost:8001',
    apiUrlAcademic: 'http://localhost:8004',
    apiUrlChat: 'http://localhost:8006',
    apiUrlNotes: 'http://localhost:8005',
    apiUrlStorage: 'http://localhost:8003',
    apiUrlExams: 'http://localhost:8000',
    apiUrlForms: 'http://localhost:8009',
    apiUrlBuckets: 'http://localhost:8012',
    apiUrlNotifications: 'http://localhost:8007',
    apiUrlSpaces: 'http://localhost:8013',
    apiUrlQflow: 'http://localhost:8010',
    apiUrlPages: 'https://ucpages.uc.pt',
    apiUrlApply: 'http://localhost:8011',
    apiUrlTasks: 'http://localhost:8015',
    apiUrlActivity: 'http://localhost:8014',
    apiUrlMyUC: 'http://localhost:8018',
    apiUrlAnalytics: 'http://localhost:8019',
    apiUrlSocialSupport: 'http://localhost:8026',
    appUrlOne: 'http://localhost:8017',
    appUrlUCId: 'http://localhost:9000',
    ucpluginUrl: 'http://localhost:9004',
    ucmeetingsUrl: 'http://localhost:9003',
    ucpreviewUrl: 'https://preview.dev.ucframework.pt',
    apiUrlContent: 'https://content.fw.dev.ucframework.pt',
    apiUrlCompetitions: 'https://competitions.fw.dev.ucframework.pt/',
    apiUrlCourses: 'https://courses.fw.dev.ucframework.pt',
    //secureDomains are used to protect the user in case of wrong redirect in the notifications
    secureDomains: ['uc.pt', 'ucframework.pt'],
    semesterStart: ['09-01', '02-06'], //Start dates of semester: [1st Semester, 2nd Semester] format: MM-DD
    institutionalDomains: INSTITUTIONAL_DOMAINS,
    allowExternalAccountsRegister: process.env.VUE_APP_EXTERNAL_ACCOUNTS_REGISTER === 'true' ? true : false,
    allowExternalAccountsLogin: process.env.VUE_APP_EXTERNAL_ACCOUNTS_LOGIN === 'true' ? true : false,
    appsUCIdSSO: APPS_USING_UCID_SSO,
  },
  development: {
    appMeta: getCurrentAppConfig(),
    wsUrl: 'wss://fw.dev.ucframework.pt',
    apiUrlIduc: 'https://id.fw.dev.ucframework.pt',
    apiUrlMeetings: 'https://meetings.fw.dev.ucframework.pt',
    apiUrlAcademic: 'https://academic.fw.dev.ucframework.pt',
    apiUrlChat: 'https://chat.fw.dev.ucframework.pt',
    apiUrlNotes: 'https://notes.fw.dev.ucframework.pt',
    apiUrlStorage: 'https://storage.fw.dev.ucframework.pt',
    apiUrlExams: 'https://exams.fw.dev.ucframework.pt',
    apiUrlForms: 'https://forms.fw.dev.ucframework.pt',
    apiUrlBuckets: 'https://buckets.fw.dev.ucframework.pt',
    apiUrlNotifications: 'https://notifications.fw.dev.ucframework.pt',
    apiUrlSpaces: 'https://spaces.fw.dev.ucframework.pt',
    apiUrlQflow: 'https://qflow.fw.dev.ucframework.pt',
    apiUrlPages: 'https://ucpages.uc.pt',
    apiUrlApply: 'https://apply.fw.dev.ucframework.pt/api',
    apiUrlTasks: 'https://tasks.fw.dev.ucframework.pt',
    apiUrlActivity: 'https://activity.fw.dev.ucframework.pt',
    apiUrlAnalytics: 'https://analytics.fw.dev.ucframework.pt',
    apiUrlMyUC: 'https://myuc-2.fw.dev.ucframework.pt',
    apiUrlSocialSupport: 'https://socialsupport.fw.dev.ucframework.pt',
    appUrlOne: 'https://one.dev.ucframework.pt',
    appUrlUCId: 'https://ucid.dev.ucframework.pt',
    ucpluginUrl: 'https://plug.dev.ucframework.pt',
    ucmeetingsUrl: 'https://ucmeetings.dev.ucframework.pt',
    ucpreviewUrl: 'https://preview.dev.ucframework.pt',
    apiUrlContent: 'https://content.fw.dev.ucframework.pt',
    apiUrlCompetitions: 'https://competitions.fw.dev.ucframework.pt/',
    apiUrlCourses: 'https://courses.fw.dev.ucframework.pt',
    //secureDomains are used to protect the user in case of wrong redirect in the notifications
    secureDomains: ['uc.pt', 'ucframework.pt'],
    semesterStart: ['09-01', '02-06'], //Start dates of semester: [1st Semester, 2nd Semester] format: MM-DD
    institutionalDomains: INSTITUTIONAL_DOMAINS,
    allowExternalAccountsRegister: process.env.VUE_APP_EXTERNAL_ACCOUNTS_REGISTER === 'true' ? true : false,
    allowExternalAccountsLogin: process.env.VUE_APP_EXTERNAL_ACCOUNTS_LOGIN === 'true' ? true : false,
    appsUCIdSSO: APPS_USING_UCID_SSO,
  },
  staging: {
    appMeta: getCurrentAppConfig(),
    wsUrl: 'wss://fw.staging.ucframework.pt',
    apiUrlIduc: 'https://id.fw.staging.ucframework.pt',
    apiUrlMeetings: 'https://meetings.fw.staging.ucframework.pt',
    apiUrlAcademic: 'https://academic.fw.staging.ucframework.pt',
    apiUrlChat: 'https://chat.fw.staging.ucframework.pt',
    apiUrlNotes: 'https://notes.fw.staging.ucframework.pt',
    apiUrlStorage: 'https://storage.fw.staging.ucframework.pt',
    apiUrlExams: 'https://exams.fw.staging.ucframework.pt',
    apiUrlForms: 'https://forms.fw.staging.ucframework.pt',
    apiUrlBuckets: 'https://buckets.fw.staging.ucframework.pt',
    apiUrlNotifications: 'https://notifications.fw.staging.ucframework.pt',
    apiUrlSpaces: 'https://spaces.fw.staging.ucframework.pt',
    apiUrlQflow: 'https://qflow.fw.staging.ucframework.pt',
    apiUrlPages: 'https://ucpages.uc.pt',
    apiUrlApply: 'https://apply-2.fw.staging.ucframework.pt/api',
    apiUrlTasks: 'https://tasks.fw.staging.ucframework.pt',
    apiUrlActivity: 'https://activity.fw.staging.ucframework.pt',
    apiUrlMyUC: 'https://myuc-2.fw.staging.ucframework.pt',
    apiUrlAnalytics: 'https://analytics.fw.staging.ucframework.pt',
    apiUrlSocialSupport: 'https://socialsupport.fw.staging.ucframework.pt',
    ucpluginUrl: 'https://plug.staging.ucframework.pt',
    ucmeetingsUrl: 'https://ucmeetings.staging.ucframework.pt',
    ucpreviewUrl: 'https://preview.staging.ucframework.pt',
    appUrlOne: 'https://one.staging.ucframework.pt',
    appUrlUCId: 'https://ucid.staging.ucframework.pt',
    apiUrlContent: 'https://content.fw.staging.ucframework.pt',
    apiUrlCompetitions: 'https://competitions.fw.staging.ucframework.pt/',
    apiUrlCourses: 'https://courses.fw.staging.ucframework.pt',
    //secureDomains are used to protect the user in case of wrong redirect in the notifications
    secureDomains: ['uc.pt', 'ucframework.pt'],
    semesterStart: ['09-01', '02-06'], //Start dates of semester: [1st Semester, 2nd Semester] format: MM-DD
    institutionalDomains: INSTITUTIONAL_DOMAINS,
    allowExternalAccountsRegister: process.env.VUE_APP_EXTERNAL_ACCOUNTS_REGISTER === 'true' ? true : false,
    allowExternalAccountsLogin: process.env.VUE_APP_EXTERNAL_ACCOUNTS_LOGIN === 'true' ? true : false,
    appsUCIdSSO: APPS_USING_UCID_SSO,
  },
  production: {
    appMeta: getCurrentAppConfig(),
    wsUrl: 'wss://fw.uc.pt',
    apiUrlIduc: 'https://id.fw.uc.pt',
    apiUrlMeetings: 'https://meetings.fw.uc.pt',
    apiUrlAcademic: 'https://academic.fw.uc.pt',
    apiUrlChat: 'https://chat.fw.uc.pt',
    apiUrlNotes: 'https://notes.fw.uc.pt',
    apiUrlStorage: 'https://storage.fw.uc.pt',
    apiUrlExams: 'https://exams.fw.uc.pt',
    apiUrlForms: 'https://forms.fw.uc.pt',
    apiUrlBuckets: 'https://buckets.fw.uc.pt',
    apiUrlNotifications: 'https://notifications.fw.uc.pt',
    apiUrlSpaces: 'https://spaces.fw.uc.pt/api',
    apiUrlPages: 'https://ucpages.uc.pt',
    apiUrlApply: 'https://apply.fw.uc.pt/api',
    apiUrlActivity: 'https://activity.fw.uc.pt',
    apiUrlAnalytics: 'https://analytics.fw.uc.pt',
    apiUrlTasks: 'https://tasks.fw.uc.pt',
    apiUrlMyUC: 'https://myuc-2.fw.uc.pt',
    apiUrlSocialSupport: 'https://socialsupport.fw.uc.pt',
    apiUrlQflow: 'https://qflow.fw.uc.pt',
    appUrlOne: 'https://one.uc.pt',
    appUrlUCId: 'https://ucid.uc.pt',
    ucpluginUrl: 'https://ucplugin.uc.pt',
    ucmeetingsUrl: 'https://ucmeetings.uc.pt',
    preview_url: 'https://preview.uc.pt',
    apiUrlContent: 'https://content.fw.uc.pt',
    apiUrlCompetitions: 'https://competitions.fw.uc.pt',
    apiUrlCourses: 'https://courses.fw.uc.pt',
    //secureDomains are used to protect the user in case of wrong redirect in the notifications
    secureDomains: ['uc.pt'],
    semesterStart: ['09-01', '02-06'], //Start dates of semester: [1st Semester, 2nd Semester] format: MM-DD
    institutionalDomains: INSTITUTIONAL_DOMAINS,
    allowExternalAccountsRegister: process.env.VUE_APP_EXTERNAL_ACCOUNTS_REGISTER === 'true' ? true : false,
    allowExternalAccountsLogin: process.env.VUE_APP_EXTERNAL_ACCOUNTS_LOGIN === 'true' ? true : false,
    appsUCIdSSO: APPS_USING_UCID_SSO,
  },
}

const FwEnvConfig =
  fwRealEnv == 'production'
    ? envConfig.production
    : fwRealEnv == 'alllocal'
    ? envConfig.alllocal
    : fwRealEnv == 'local'
    ? envConfig.local
    : fwRealEnv == 'development'
    ? envConfig.development
    : envConfig.staging

export default FwEnvConfig
