<template>
  <b-modal
    v-if="attendee && meeting"
    :active.sync="isActive"
    :width="800"
    scroll="keep"
    has-modal-card
    trap-focus
    aria-role="dialog"
    aria-modal
    :on-cancel="closeModal"
  >
    <div class="modal-card" style="width: auto; min-width: 800px">
      <section class="modal-card-body">
        <div class="has-padding has-background-light is-rounded">
          <div class="is-flex is-flex-space-between">
            <div class="has-margin is-flex is-flex-align-center">
              <UserAvatar v-if="attendee" size="is-medium" :user="attendee.user" />
              <div class="has-margin-left">
                <div class="label is-marginless">Permissões</div>
                <div v-if="attendee" class="is-size-3">{{ attendee.user.name }}</div>
                <div v-if="!attendee.user.key" class="is-size-5">Convidado</div>
              </div>
            </div>
            <div>
              <b-button type="is-text" icon-right="times" @click="closeModal">Fechar</b-button>
            </div>
          </div>
          <div class="columns has-text-centered has-margin-top">
            <div class="column">
              <div class="is-size-3 has-margin-bottom has-text-weight-bold">Promover<br />a orador</div>
              <div :class="{ 'has-text-primary': attendee.withRole('show_to_everyone') }">
                <faicon class="has-margin-right" icon="users"></faicon>
                <faicon class="has-margin-right" icon="video"></faicon>
                <faicon icon="microphone"></faicon>
              </div>
              <div class="has-margin-top has-margin-bottom-medium">
                <b-button
                  :type="attendee.withRole('show_to_everyone') ? 'is-primary' : 'is-dark is-outlined'"
                  @click="togglePermissions('show_to_everyone')"
                  >{{ attendee.withRole('show_to_everyone') ? 'Permitido' : 'Permitir' }}</b-button
                >
              </div>
              <div class="has-text-small has-text-muted">
                Um participante promovido a orador poderá ligar o vídeo e áudio para que todos os participantes o possam
                ver e ouvir.
              </div>
            </div>
            <div class="column">
              <div class="is-size-3 has-margin-bottom has-text-weight-bold">Falar<br />com oradores</div>
              <div>
                <faicon
                  :class="{ 'has-text-primary': attendee.withRole('camera_allowed') }"
                  class="has-margin-right"
                  icon="video"
                ></faicon>
                <faicon :class="{ 'has-text-primary': attendee.withRole('audio_allowed') }" icon="microphone"></faicon>
              </div>
              <div class="has-margin-top has-margin-bottom-medium">
                <b-button
                  class="has-margin-right-medium"
                  aria-label="Autorizar ligar câmara"
                  :type="attendee.withRole('camera_allowed') ? 'is-primary' : 'is-dark is-outlined'"
                  :icon-left="attendee.withRole('camera_allowed') ? 'video' : 'video-slash'"
                  @click="togglePermissions('camera_allowed')"
                ></b-button>
                <b-button
                  aria-label="Autorizar ligar microfone"
                  :type="attendee.withRole('audio_allowed') ? 'is-primary' : 'is-dark is-outlined'"
                  :icon-left="attendee.withRole('audio_allowed') ? 'microphone' : 'microphone-slash'"
                  @click="togglePermissions('audio_allowed')"
                ></b-button>
              </div>
              <div class="has-text-small has-text-muted">
                Com esta permissão um participante poderá ligar o áudio e vídeo mas apenas os responsáveis pela aula /
                reunião podem ver e ouvir.
              </div>
            </div>
            <div class="column">
              <div class="is-size-3 has-margin-bottom has-text-weight-bold">Partilhar<br />ecrã</div>
              <div :class="{ 'has-text-primary': attendee.withRole('sharescreen_allowed') }">
                <faicon icon="desktop"></faicon>
              </div>
              <div class="has-margin-top has-margin-bottom-medium">
                <b-button
                  :type="attendee.withRole('sharescreen_allowed') ? 'is-primary' : 'is-dark is-outlined'"
                  @click="togglePermissions('sharescreen_allowed')"
                  >{{ attendee.withRole('sharescreen_allowed') ? 'Permitido' : 'Permitir' }}</b-button
                >
              </div>
              <div class="has-text-small has-text-muted">
                Permitir que um participante partilhe o seu ecrã para todos.
              </div>
            </div>
            <div class="column">
              <div class="is-size-3 has-margin-bottom has-text-weight-bold">Códigos<br />UC Plugin</div>
              <div :class="{ 'has-text-primary': attendee.withRole('plugin_allowed') }">
                <faicon icon="plug"></faicon>
              </div>
              <div class="has-margin-top has-margin-bottom-medium">
                <b-button
                  :type="attendee.withRole('plugin_allowed') ? 'is-primary' : 'is-dark is-outlined'"
                  @click="togglePermissions('plugin_allowed')"
                  >{{ attendee.withRole('plugin_allowed') ? 'Permitido' : 'Permitir' }}</b-button
                >
              </div>
              <div class="has-text-small has-text-muted">
                Permitir que um participante tenha acesso ao painel UC Plugin, para gerar código de autorização para
                dispositivos móveis.
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </b-modal>
</template>

<script>
import UserAvatar from '@/fw-modules/fw-core-vue/id/components/user/UserAvatar'
import ServiceMeetings from '@/fw-modules/fw-meetings-vue/services/ServiceMeetings'

export default {
  components: {
    UserAvatar,
  },

  props: {
    show: Boolean,
    attendee: Object,
    meeting: Object,
  },

  data() {
    return {
      isActive: this.show || false,
    }
  },

  computed: {
    loggedUser() {
      return this.$store.getters.getUser
    },
  },

  watch: {
    show(value) {
      this.isActive = value
    },
  },

  methods: {
    closeModal() {
      this.isActive = false
      this.$emit('close', false)
    },

    async togglePermissions(permission) {
      const roles = {}
      const newRole = !this.attendee.withRole(permission)
      roles[ServiceMeetings.getRole(permission)] = newRole
      if (permission == 'show_to_everyone' && newRole) {
        roles[ServiceMeetings.getRole('audio_allowed')] = newRole
        roles[ServiceMeetings.getRole('camera_allowed')] = newRole
      }

      this.attendee.roles = await ServiceMeetings.changeAttendeeRoles(this.meeting.key, this.attendee.key, roles)

      this.$emit('reload-page')
    },
  },
}
</script>
