<template>
  <span class="flex" :class="[{ 'absolute top-1 right-1': !inline, relative: inline }, wh]">
    <span
      class="absolute inline-flex h-full w-full rounded-full opacity-75"
      :class="{
        'animate-ping': ping,
        'bg-primary': color === 'primary',
        'bg-yellow-600': color === 'orange',
        'bg-white': color === 'white',
        'bg-gray-500 opacity-20': color === 'light',
      }"
    ></span>
    <span
      class="relative inline-flex rounded-full"
      :class="[
        {
          'bg-primary': color === 'primary',
          'bg-white': color === 'white',
          'bg-yellow-600': color === 'orange',
          'bg-gray-500 opacity-20': color === 'light',
        },
        wh,
      ]"
    ></span>
  </span>
</template>

<script>
export default {
  props: {
    ping: {
      type: Boolean,
      default: true,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'primary',
    },
    wh: {
      type: String,
      default: 'h-2 w-2',
    },
  },
}
</script>
