var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ucmeetings my-webcam z-20 absolute overflow-hidden",class:{
    'is-minimal w-28 max-h-16 top-0 right-0': _vm.isMinimal,
    'right-4 top-20 w-72 drop-shadow': !_vm.isMinimal,
    'mirror-disabled': _vm.camera.mirrorDisabled,
    'cursor-pointer': !_vm.isMobile,
    'screen-sharing': _vm.screenShare.active,
    hidden: !_vm.camera.active,
  },on:{"click":function($event){_vm.minimal = _vm.isMobile ? false : !_vm.minimal}}},[_c('div',{staticClass:"video-stream z-0"},[_c('video',{staticClass:"webcam",class:{ 'video-off': !_vm.camera.active },attrs:{"id":"video-my-webcam","autoplay":"","playsinline":"","muted":"muted","poster":"/img/pod-video-poster.png?v=2"},domProps:{"muted":true}}),_c('video',{staticClass:"screenshare",attrs:{"id":"video-my-screen","autoplay":"","playsinline":"","muted":"muted"},domProps:{"muted":true}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }