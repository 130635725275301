<template>
  <b-modal
    :active.sync="isActive"
    :on-cancel="closeModal"
    has-modal-card
    aria-role="dialog"
    aria-modal
    class="meetings-modal-pods-enter-queue"
  >
    <div class="modal-card is-width-tiny">
      <section class="modal-card-body">
        <div class="flex flex-col justify-center items-center gap-2 mt-5 text-primary">
          <div
            class="p-5 bg-primary rounded-full flex items-center justify-center text-white"
            :class="{ 'bg-opacity-100': isAudioOn, 'bg-opacity-90': !isAudioOn }"
          >
            <svg
              class="fill-current"
              :class="{ 'animate-pulse w-14 h-14': isAudioOn, 'w-10 h-10': !isAudioOn }"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M1 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H1zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zM21.548.784A13.942 13.942 0 0 1 23 7c0 2.233-.523 4.344-1.452 6.216l-1.645-1.196A11.955 11.955 0 0 0 21 7c0-1.792-.393-3.493-1.097-5.02L21.548.784zm-3.302 2.4A9.97 9.97 0 0 1 19 7a9.97 9.97 0 0 1-.754 3.816l-1.677-1.22A7.99 7.99 0 0 0 17 7a7.99 7.99 0 0 0-.43-2.596l1.676-1.22z"
              />
            </svg>
          </div>
          <fw-heading size="h3">Tem a palavra!</fw-heading>
        </div>
        <div v-if="!isAudioOn" class="my-5 text-center font-medium text-sm text-gray-500">
          O seu microfone foi desbloqueado.<br />Para falar, ligue o seu microfone.
        </div>
        <div class="my-5 flex items-center justify-center">
          <BlockAudio
            :with-janus="withJanus"
            :with-labels="true"
            :attendee="attendee"
            :audio="audio"
            :audio-input-options="audioInputOptions"
            :max-width="200"
          />
        </div>
        <div v-if="isAudioOn" class="font-semibold text-primary text-center m-2">
          Os participantes já estão a ouvi-lo(a). Já pode fechar esta janela.
        </div>
        <div class="text-center">
          <fw-button type="link-muted" @click.native="closeModal">Fechar janela</fw-button>
        </div>
      </section>
    </div>
  </b-modal>
</template>

<script>
import BlockAudio from '@/fw-modules/fw-meetings-vue/components/blocks/BlockAudio'

export default {
  components: {
    BlockAudio,
  },

  props: {
    showAudioUnlockedModal: Boolean,
    withJanus: Boolean,
    attendee: Object,
    audio: Object,
    audioInputOptions: Array,
  },

  data() {
    return {
      isActive: this.showAudioUnlockedModal,
    }
  },

  computed: {
    isAudioOn() {
      return this.audio && this.audio.active
    },
  },

  watch: {
    showAudioUnlockedModal(value) {
      this.isActive = value
    },
  },

  methods: {
    closeModal() {
      this.$emit('close-modal')
      this.$emit('close', false)
    },
  },
}
</script>
