<template>
  <div
    class="flex gap-1 items-center relative fw-card-file"
    :class="{
      'bg-teal-400': innerSelected || isPreviewOpen,
      'justify-between p-2 my-1 group hover:bg-opacity-40 hover:shadow-xl rounded-lg': isRow,
      'flex-col items-center justify-between p-2 md:p-4 my-1 group hover:bg-opacity-40 hover:shadow-xl rounded-xl': !isRow,
      'bg-opacity-30 shadow-md': isPreviewOpen,
      'bg-opacity-10 shadow-sm': !isPreviewOpen,
    }"
  >
    <div
      v-if="!isRow && item.file && item.file.type == 'image'"
      class="absolute top-0 left-0 w-full h-full z-0 overflow-hidden"
      :class="{ 'bg-opacity-10 rounded-xl': !isRow && item.file.type == 'image' }"
    >
      <img
        :src="getImageViewUrl(item.file, 'max400')"
        class="w-full h-full object-cover scale-110 group-hover:opacity-30 group-hover:grayscale group-hover:blur-sm"
        :class="{ 'opacity-30 grayscale blur-sm': isPreviewOpen, 'opacity-100': !isPreviewOpen }"
      />
    </div>
    <div
      class="group-hover:opacity-95 -mr-3 mt-1"
      :class="{ 'opacity-95': innerSelected, 'opacity-0': !innerSelected, 'absolute top-3 left-3': !isRow }"
    >
      <b-checkbox v-model="innerSelected" size="is-small"></b-checkbox>
    </div>
    <!-- Icon -->
    <div>
      <button
        v-if="item.type === 'folder'"
        class="group-hover:scale-105 inline-flex items-center flex-shrink-0 justify-center cursor-pointer"
        :class="{
          'w-10 h-10': isRow && type === 'row_expanded',
          'w-5 h-5 mt-1': isRow && type !== 'row_expanded',
          'w-14 h-14': !isRow,
        }"
        @click="$emit('go-to-folder', item.key)"
      >
        <fw-icon-folder-solid
          class="text-teal-600 group-hover:hidden icon-folder-solid"
          :class="{
            'w-9 h-9': isRow && type === 'row_expanded',
            'w-5 h-5': isRow && type !== 'row_expanded',
            'w-10 h-10': !isRow,
          }"
        />
        <fw-icon-folder-open-solid
          class="hidden group-hover:flex text-teal-600 icon-folder-open-solid"
          :class="{
            'w-9 h-9': isRow && type === 'row_expanded',
            'w-5 h-5': isRow && type !== 'row_expanded',
            'w-10 h-10': !isRow,
          }"
        />
      </button>
      <button
        v-else
        class="bg-opacity-50 group-hover:bg-opacity-90 group-hover:bg-white rounded-full inline-flex items-center flex-shrink-0 justify-center"
        :class="{
          'w-10 h-10 bg-gray-200 overflow-hidden group-hover:scale-105': isRow && type === 'row_expanded',
          'w-5 h-5 bg-white mt-1': isRow && type !== 'row_expanded',
          'w-14 h-14 bg-gray-200 overflow-hidden group-hover:scale-105': !isRow,
          'bg-gray-200 scale-110 group-hover:scale-110 bg-white bg-opacity-90 overflow-hidden group-hover:scale-105 ': isPreviewOpen,
        }"
        @click="preview"
      >
        <img
          v-if="item.file.type == 'image' && type !== 'row'"
          class="group-hover:absolute group-hover:top-0 group-hover:left-0 group-hover:w-full group-hover:h-full"
          :src="getImageViewUrl(item.file, '100')"
        />
        <component
          :is="'fw-icon-' + fileIconKey"
          v-else
          class="opacity-90 group-hover:scale-105"
          :class="{ 'w-5 h-5': isRow, 'w-9 h-9': !isRow }"
        />
      </button>
    </div>
    <!-- Main metadata -->
    <div class="flex-1 text-sm md:text-base ml-1">
      <div class="flex flex-col" :class="{ 'items-center justify-center h-full': !isRow }">
        <div
          class="flex items-center justify-between"
          :class="{
            'flex-col': !isRow,
            'invisible group-hover:visible': !isRow && item.file && item.file.type == 'image' && !isPreviewOpen,
          }"
        >
          <button
            v-if="item.type === 'folder'"
            href=""
            class="inline-flex"
            :class="{ 'font-bold': item.unread }"
            @click="$emit('go-to-folder', item.key)"
          >
            <v-clamp autoresize :max-lines="1">{{ item.title }}</v-clamp>
          </button>
          <button v-else class="inline-flex break-all" :class="{ 'font-bold': item.unread }" @click="preview">
            <v-clamp autoresize :max-lines="1">{{ item.title || item.file.filename }}</v-clamp>
          </button>
          <span v-if="isMobile" class="opacity-60 text-sm leading-5" :class="{ hidden: !isRow }">
            {{ item.created_date | formatDateTime }}
          </span>
          <!-- <span v-if="!isMobile && type === 'row_expanded'" class="text-xs opacity-30" :class="{ hidden: !isRow }">
            {{ item.key }}
          </span>
          <span v-else class="opacity-60 text-sm leading-5" :class="{ hidden: !isRow }">
            {{ item.created_date | formatDateTime }}
          </span> -->
          <!-- <span class="opacity-60 text-sm leading-5" :class="{ hidden: !isRow }">
            {{ item.created_date | formatDateTime }}
          </span> -->
        </div>
        <div
          v-if="type === 'row_expanded'"
          class="text-xs md:text-sm flex opacity-60"
          :class="{
            'gap-1 justify-between leading-5': isRow,
            'flex-col items-center justify-center gap-0.5 mt-2': !isRow,
            'invisible group-hover:visible': !isRow && item.file && item.file.type == 'image' && !isPreviewOpen,
          }"
        >
          <div v-if="item.type === 'file'" class="flex gap-3 pr-2 break-all">
            <v-clamp v-if="typeof item.metadata['dc.description'] !== 'undefined'" autoresize :max-lines="2">{{
              item.metadata['dc.description']
            }}</v-clamp>
            <span
              v-if="
                (isMobile &&
                  typeof item.metadata['dc.description'] === 'string' &&
                  item.metadata['dc.description'].length === 0) ||
                  typeof item.metadata['dc.description'] === 'undefined' ||
                  !isMobile
              "
              class="whitespace-nowrap flex-shrink-0"
              >{{ item.metadata.size | bytesToString }}</span
            >
            <!-- <v-clamp v-if="item.title != item.file.filename" autoresize :max-lines="1">{{
              item.file.filename
            }}</v-clamp> -->
            <span
              v-if="
                typeof item.metadata['dc.description'] === 'undefined' ||
                  (typeof item.metadata['dc.description'] === 'string' && item.metadata['dc.description'].length === 0)
              "
              class="no-description"
              >Sem descrição</span
            >
          </div>
          <!-- <div v-else-if="item.type === 'file'" class="flex gap-3 break-all"></div> -->
          <div v-else>
            <div v-if="item.stats.folders || item.stats.files" class="flex gap-1 md:gap-2 items-center">
              <div class="flex items-center">
                <fw-icon-scale class="w-3 h-3" /> {{ item.stats.size | bytesToString }}
              </div>
              <div class="flex items-center gap-1"><fw-icon-folder class="w-3 h-3" /> {{ item.stats.folders }}</div>
              <div class="flex items-center gap-1"><fw-icon-file class="w-3 h-3" /> {{ item.stats.files }}</div>
            </div>
            <div v-else class="text-xs md:text-sm">Pasta vazia</div>
          </div>
          <!-- <div v-if="!isMobile" class="whitespace-nowrap">{{ item.created_date | formatDateTime }}</div> -->
        </div>
      </div>
    </div>
    <!-- Date -->
    <div v-if="!isMobile" class="opacity-60 text-sm leading-5" :class="{ hidden: !isRow }">
      {{ item.created_date | formatDateTime }}
    </div>
    <!-- Nav -->
    <div class="flex" :class="{ 'flex-shrink-0 items-center gap-1 md:ml-4': isRow, 'absolute top-1 right-2': !isRow }">
      <span class="w-7">
        <a
          v-if="false && item.type === 'file' && item.file.type != 'video' && isRow"
          target="_blank"
          :href="getFileUrl(item.file)"
          class="bg-primary bg-opacity-10 rounded-full p-1 flex items-center justify-center"
        >
          <fw-icon-cloud-download class="w-5 h-5" />
        </a>
        <span
          v-if="item.type === 'file' && isRow && item.lock_download !== true"
          class="bg-primary bg-opacity-10 rounded-full p-1 flex items-center justify-center"
          @click="dowloadFile"
        >
          <fw-icon-cloud-download class="w-5 h-5" />
        </span>
      </span>
      <b-dropdown v-if="canDeleteItems || canEditItems" aria-role="list" position="is-bottom-left">
        <template #trigger="{ active }">
          <fw-button size="sm" :class="{ 'opacity-100': active }">
            <fw-icon-more class="w-5 h-5" />
          </fw-button>
        </template>
        <b-dropdown-item v-if="canEditItems" paddingless aria-role="listitem">
          <fw-button
            type="simple"
            size="sm"
            class="w-full flex items-center gap-2"
            label="Editar título"
            @click.native="editItemTitle"
          >
            <fw-icon-edit class="w-5 h-5" /> Editar título
          </fw-button>
        </b-dropdown-item>
        <b-dropdown-item v-if="canMoveItems" paddingless aria-role="listitem">
          <fw-button
            type="simple"
            size="sm"
            class="w-full flex items-center gap-2"
            label="Copiar para..."
            @click.native="$emit('copy-to-repo', item)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              class="w-5 h-5 fill-current"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M7 6V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-3v3c0 .552-.45 1-1.007 1H4.007A1.001 1.001 0 0 1 3 21l.003-14c0-.552.45-1 1.006-1H7zM5.002 8L5 20h10V8H5.002zM9 6h8v10h2V4H9v2zm-2 5h6v2H7v-2zm0 4h6v2H7v-2z"
              />
            </svg>
            Copiar para...
          </fw-button>
        </b-dropdown-item>
        <b-dropdown-item v-if="canDeleteItems" paddingless aria-role="listitem">
          <fw-button
            type="simple"
            size="sm"
            class="w-full flex items-center gap-2"
            label="Eliminar"
            @click.native="$emit('delete', item)"
          >
            <fw-icon-trash class="w-5 h-5" /> Eliminar
          </fw-button>
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>
<script>
import ServiceBuckets from '@/fw-modules/fw-core-vue/buckets/services/ServiceBuckets'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  props: {
    type: {
      type: String,
      default: 'row',
    },
    read: {
      type: Boolean,
      default: true,
    },
    bucket: Object,
    isPreviewOpen: Boolean,
    canEditItems: Boolean,
    canDeleteItems: Boolean,
    canMoveItems: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      deep: true,
    },
    initSelected: Boolean,
  },

  data() {
    return {
      loading: false,
      inputOpened: false,
      newMetadataItem: this.getNewMetadataItem(),
      innerSelected: this.initSelected,
    }
  },

  computed: {
    isMobile() {
      return window.innerWidth < 640
    },
    isRow() {
      return this.type === 'row' || this.type === 'row_expanded'
    },
    newMetadataItemIsComplete() {
      return Boolean(this.newMetadataItem.title && this.newMetadataItem.value)
    },
    fileIconKey() {
      return utils.getFileIcon(this.item.file.filename)
    },
  },

  watch: {
    innerSelected(value) {
      this.$emit('set-selected', this.item.key, value)
    },
  },

  methods: {
    dowloadFile() {
      this.$emit('download', this.item.key, this.item.file)
    },
    updateInnerSelected(value) {
      this.innerSelected = value
    },
    getNewMetadataItem() {
      return { title: '', value: '' }
    },
    clearNewMetadataItem() {
      this.newMetadataItem = this.getNewMetadataItem()
    },
    getFileIcon(filename) {
      return utils.getFileIcon(filename)
    },
    getFileUrl(file) {
      return ServiceStorage.getFileUrl(file)
    },
    getImageViewUrl(file, size) {
      return ServiceStorage.getImageViewUrl(file, size)
    },

    openEdit() {
      if (this.canEditItems) {
        this.clearNewMetadataItem()
        this.inputOpened = true
        this.$nextTick(() => {
          const el = document.getElementById('input-title-' + this.item.key)
          if (el) el.focus()
        })
      }
    },
    addNewItemMetadata() {
      if (this.newMetadataItemIsComplete) {
        this.item.metadata.push({
          key: utils.randomLowerString(8),
          title: this.newMetadataItem.title,
          value: this.newMetadataItem.value,
        })
        this.clearNewMetadataItem()
      }
    },
    removeItemMetadata(metadataItemKey) {
      for (let idx in this.item.metadata) {
        if (this.item.metadata[idx].key === metadataItemKey) {
          this.item.metadata.splice(idx, 1)
          break
        }
      }
    },
    editItemTitle() {
      if (!this.canEditItems) return
      this.$buefy.dialog.prompt({
        title: 'Título do recurso',
        inputAttrs: {
          type: 'text',
          placeholder: 'e.g. Nome do ficheiro ou pasta',
          maxlength: 150,
          min: 2,
          value: this.item.title,
        },
        confirmText: 'Alterar',
        cancelText: 'Cancelar',
        trapFocus: true,
        onConfirm: async value => {
          this.item.title = value
          this.updateItem()
        },
      })
    },
    async updateItem() {
      if (this.canEditItems) {
        try {
          const data = { title: this.item.title }
          const updatedItem = await ServiceBuckets.updateBucketItem(this.bucket.key, this.item.key, data)
          Object.assign(this.item, updatedItem)
          this.inputOpened = false
        } catch (error) {
          this.$buefy.snackbar.open({
            message: 'Ocorreu um erro a guardar os dados do item.',
            type: 'is-warning',
            position: 'is-top-right',
            indefinite: true,
            duration: 2000,
            queue: false,
          })
        }
      }
    },
    preview() {
      this.$emit('preview', this.item)
    },
  },
}
</script>
<style scoped>
.fw-card-file .no-description {
  display: none;
}
.fw-card-file:hover .no-description {
  display: block;
}
</style>
