<template>
  <fw-layout footer :back-to-enable="false">
    <template #main-content>
      <PanelUCIdHero :user="user" class="mt-4 mb-10" version="v2" />
    </template>
  </fw-layout>
</template>

<script>
import PanelUCIdHero from '@/fw-modules/fw-core-vue/id/components/panels/PanelUCIdHero'

export default {
  components: {
    PanelUCIdHero,
  },
  mounted() {
    this.$router.push({ name: 'manage-home' })
  },
}
</script>
