<template>
  <div class="relative">
    <div class="flex flex-col">
      <fw-panel :title="$t('newSpace')" featured :loading="savingData" after-loading-checked> </fw-panel>

      <div class="my-2">
        <fw-label>{{ $t('type.label') }}</fw-label>
        <b-select v-model="space.type">
          <option v-for="option in availableTypes" :key="option" :value="option">
            {{ $t(`spaceTypes.${option}`) }}
          </option>
        </b-select>
      </div>

      <div class="mb-2">
        <fw-label>{{ $t('prefix.label') }}</fw-label>
        <TextInput
          v-model.trim="space.prefix"
          :minlength="1"
          :maxlength="10"
          :placeholder="$t('prefix.placeholder')"
          :help="$t('charsLimit', { limit: '10' })"
          uppercase
          :class="{
            error: $v.space.prefix.$error,
          }"
        >
        </TextInput>
        <fw-tip v-if="$v.space.prefix.$error" error>
          <span v-if="!$v.space.prefix.required">{{ $t('prefix.required') }}</span>
          <span v-else-if="!$v.space.prefix.alphanumeric">{{ $t('prefix.alphanumeric') }}</span>
          <span v-else>{{ $t('prefix.invalid') }}</span>
        </fw-tip>
      </div>

      <div class="mb-2">
        <fw-label>{{ $t('title.label') }}</fw-label>
        <TextInput
          v-model="space.title"
          :minlength="1"
          :maxlength="250"
          :placeholder="$t('title.placeholder')"
          :class="{
            error: $v.space.title.$error,
          }"
        >
        </TextInput>

        <fw-tip v-if="$v.space.title.$error" error>
          <span v-if="!$v.space.title.required">{{ $t('title.required') }}</span>
          <span v-else>{{ $t('title.invalid') }}</span>
        </fw-tip>
      </div>

      <div class="mb-2">
        <fw-label>{{ $t('teamName.label') }}</fw-label>
        <TextInput
          v-model="space.team_name"
          :minlength="2"
          :maxlength="50"
          :placeholder="$t('teamName.placeholder')"
          :class="{
            error: $v.space.team_name.$error,
          }"
        >
        </TextInput>

        <fw-tip v-if="$v.space.team_name.$error" error>
          <span v-if="!$v.space.team_name.required">{{ $t('teamName.required') }}</span>
          <span v-else>{{ $t('teamName.invalid') }}</span>
        </fw-tip>
      </div>

      <div class="flex items-center justify-end gap-5">
        <fw-button type="link-muted" @click.native="$emit('close')">
          {{ $t('cancel') }}
        </fw-button>

        <fw-button type="primary" @click.native="saveSpace">
          {{ $t('save') }}
        </fw-button>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from '@/fw-modules/fw-core-vue/ui/components/form/TextInput'
import { required, maxLength, minLength } from 'vuelidate/lib/validators'
import { alphanumeric } from '@/fw-modules/fw-core-vue/utilities/vuelidateHelpers'

export default {
  components: {
    TextInput,
  },

  props: {
    savingData: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      space: {
        title: null,
        prefix: null,
        type: 'support',
      },
      availableTypes: ['support', 'management'],
    }
  },

  validations: {
    space: {
      prefix: { required, min: minLength(1), max: maxLength(10), alphanumeric },
      title: { required, min: minLength(2), max: maxLength(250) },
      type: { required },
      team_name: { required, min: minLength(2), max: maxLength(50) },
    },
  },

  methods: {
    toggleEditMode() {
      if (!this.editModeOnly) return
      this.editMode = !this.editMode
    },

    saveSpace() {
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.$emit('save', this.space)
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "charsLimit": "Limite de {limit} caracteres alfanuméricos.",
    "thereAreErrors": "Existem erros de formulário",
    "newSpace": "Novo espaço",
    "save": "Guardar",
    "cancel": "Cancelar",
    "edit": "Editar",
    "title": {
      "label": "Título",
      "placeholder": "Título",
      "required": "Insira um título",
      "invalid": "Insira um título válido"
    },
    "prefix": {
      "label": "Prefixo",
      "placeholder": "Prefixo",
      "required": "Insira um prefixo",
      "alphanumeric": "Insira apenas letras e números",
      "invalid": "Insira um prefixo válido"
    },
    "type": {
      "label": "Tipo",
      "placeholder": "Tipo",
      "required": "Selecione um tipo",
      "invalid": "Selecione um tipo válido"
    },
    "spaceTypes": {
      "support": "Suporte",
      "management": "Gestão de projeto"
    },
    "teamName": {
      "label": "Nome da equipa",
      "placeholder": "Nome da equipa",
      "required": "Insira o nome da equipa",
      "invalid": "Insira um nome da equipa válido"
    }
  },
  "en": {
    "cancel": "Cancel",
    "edit": "Edit",
    "charsLimit": "Limit of {limit} alphanumeric characters.",
    "thereAreErrors": "There are errors in the form",
    "newSpace": "New space",
    "save": "Save",
    "title": {
      "label": "Title",
      "placeholder": "Title",
      "required": "Enter a title",
      "invalid": "Enter a valid title"
    },
    "prefix": {
      "label": "Prefix",
      "placeholder": "Prefix",
      "required": "Enter a prefix",
      "alphanumeric": "Enter letters and numbers only",
      "invalid": "Enter a valid prefix"
    },
    "type": {
      "label": "Type",
      "placeholder": "Type",
      "required": "Select a type",
      "invalid": "Select a valid type"
    },
    "spaceTypes": {
      "support": "Support",
      "management": "Management"
    },
    "teamName": {
      "label": "Team Name",
      "placeholder": "Team Name",
      "required": "Enter a team name",
      "invalid": "Enter a valid team name"
    }
  }
}
</i18n>
