<template>
  <div
    :id="pod.id"
    class="remote-pod group overflow-hidden w-full h-full flex flex-col items-center bg-gray-800 bg-opacity-90 text-gray-300 justify-center"
    :class="{
      'fullscreen-on absolute left-0 top-0 w-full h-full': pod.fullscreen,
      'rounded-lg': !pod.fullscreen,
      floating: isFloating,
      relative: !pod.fullscreen,
      talking: pod.talking,
      'is-screen': pod.video && (pod.video.type === 'screen_share' || isPlugin || fillPod === false),
      'no-video': !pod.video,
    }"
  >
    <!-- Disconnected -->
    <div v-if="pod.attendee && pod.attendee.status == 'disconnected'" class="z-10 m-4">
      <fw-icon-wifi-off class="fill-current w-7 h-7 text-white p-1 rounded-full bg-red-500" />
    </div>

    <!-- No video -->
    <div
      v-if="!pod.video"
      class="flex items-center flex-col justify-center cursor-pointer gap-2 w-full"
      @click="$emit('toggle-fullscreen', pod)"
    >
      <Avatar
        v-if="pod.attendee && pod.attendee.status !== 'disconnected'"
        :user="pod.attendee ? pod.attendee.user : null"
        :size="!pod.fullscreen && !isFloating ? 'md' : isFloating ? '2xs' : 'lg'"
      />
      <div>
        <v-clamp
          autoresize
          :max-lines="1"
          class="name text-sm px-1 font-semibold"
          :class="{ 'text-sm': !isFloating, 'text-xs': isFloating }"
          >{{ pod.name }}</v-clamp
        >
      </div>
      <div v-if="!isFloating && pod.attendee && !pod.attendee.user.key" class="text-xs font-medium opacity-50">
        Convidado
      </div>
    </div>

    <!-- Pods actions -->
    <div v-if="pod.attendee && pod.attendee.status != 'disconnected'" class="absolute left-3 top-3 z-10">
      <b-dropdown
        v-if="!withFullscreen || pod.fullscreen"
        :triggers="['click']"
        aria-role="list"
        animation="none"
        position="is-bottom-right"
      >
        <fw-button-dropdown slot="trigger" type="transparent" size="xs" label="Opções" :chevron="false"
          ><fw-icon-more class="w-6 h-6 text-white"
        /></fw-button-dropdown>
        <b-dropdown-item
          v-if="pod.withAudio && attendee.withRole('can_mute_others')"
          aria-role="listitem"
          @click="$emit('mute-attendee', pod.attendee)"
        >
          Desativar som
        </b-dropdown-item>
        <b-dropdown-item v-if="canPromotePod(pod)" aria-role="listitem" @click="$emit('toggle-promote', pod.attendee)">
          Permissões
        </b-dropdown-item>
        <b-dropdown-item
          v-if="asWebinar && canPromotePod(pod) && pod.attendee.withRole('show_to_everyone')"
          aria-role="listitem"
          @click="$emit('toggle-promote', pod.attendee, true, false)"
        >
          Despromover (orador)
        </b-dropdown-item>
        <b-dropdown-item
          v-if="asWebinar && canPromotePod(pod) && !pod.attendee.withRole('show_to_everyone')"
          aria-role="listitem"
          @click="$emit('toggle-promote', pod.attendee, true, true)"
        >
          Promover a orador
        </b-dropdown-item>
        <b-dropdown-item
          v-if="pod.attendee && pod.attendee.user && !loggedUser.isAnonymous"
          aria-role="listitem"
          @click="$emit('go-to-user-chat', pod.attendee.user)"
        >
          Conversar
        </b-dropdown-item>
        <b-dropdown-item
          v-if="pod.attendee && attendee.withRole('can_kick')"
          aria-role="listitem"
          class="text-red-400"
          @click="$emit('kick-attendee', pod.attendee)"
        >
          Remover
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <!-- Pod info -->
    <div
      v-if="pod.attendee && pod.attendee.status != 'disconnected' && pod.type !== 'screen_share'"
      :class="{
        'absolute top-1 left-1 z-10': isFloating,
        'text-sm flex gap-2 items-center z-10 absolute bottom-4 left-4': !isFloating,
      }"
    >
      <Avatar
        v-if="pod.video && pod.attendee && pod.attendee.user && !pod.attendee.plugin && !isFloating"
        :user="pod.attendee.user"
        size="2xs"
      />
      <div v-if="!pod.withAudio && pod.type != 'screen_share'">
        <svg
          class="fill-current w-5 text-red-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M16.425 17.839A8.941 8.941 0 0 1 13 18.945V23h-2v-4.055A9.004 9.004 0 0 1 3.055 11H5.07a7.002 7.002 0 0 0 9.87 5.354l-1.551-1.55A5 5 0 0 1 7 10V8.414L1.393 2.808l1.415-1.415 19.799 19.8-1.415 1.414-4.767-4.768zm-7.392-7.392l2.52 2.52a3.002 3.002 0 0 1-2.52-2.52zm10.342 4.713l-1.443-1.442c.509-.81.856-1.73.997-2.718h2.016a8.95 8.95 0 0 1-1.57 4.16zm-2.91-2.909l-1.548-1.548c.054-.226.083-.46.083-.703V6a3 3 0 0 0-5.818-1.032L7.686 3.471A5 5 0 0 1 17 6v4a4.98 4.98 0 0 1-.534 2.251z"
          />
        </svg>
      </div>
      <div v-if="pod.attendee && pod.withAudio">
        <svg
          class="fill-current w-5"
          :class="{ 'text-primary': pod.talking, 'text-white opacity-30': !pod.talking }"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M5 7h2v10H5V7zm-4 3h2v4H1v-4zm8-8h2v18H9V2zm4 2h2v18h-2V4zm4 3h2v10h-2V7zm4 3h2v4h-2v-4z" />
        </svg>
      </div>
      <div class="flex-1 font-bold text-white max-w-sm">
        <v-clamp v-if="pod.attendee && pod.video && !isFloating" autoresize :max-lines="1" class="drop-shadow">{{
          pod.name
        }}</v-clamp>
      </div>
      <div v-if="pod.attendee && pod.video && pod.attendee.with_hd && !isFloating">
        <svg
          class="fill-current w-5 text-primary"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M4 5v14h16V5H4zM3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm4.5 8.25h2V9H11v6H9.5v-2.25h-2V15H6V9h1.5v2.25zm7-.75v3H16a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5h-1.5zM13 9h3a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-3V9z"
          />
        </svg>
      </div>
      <div v-if="pod.attendee && pod.attendee.with_issues && !isFloating">
        <svg
          class="fill-current w-5 text-red-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path fill="none" d="M0 0H24V24H0z" />
          <path
            d="M12 3c4.284 0 8.22 1.497 11.31 3.996l-1.257 1.556C19.306 6.331 15.808 5 12 5c-3.089 0-5.973.875-8.419 2.392L12 17.817l6-7.429v3.183L12 21 .69 6.997C3.78 4.497 7.714 3 12 3zm10 16v2h-2v-2h2zm0-9v7h-2v-7h2z"
          />
        </svg>
      </div>
      <div v-if="pod.attendee && pod.attendee.plugin && !isFloating" class="opacity-40 group-hover:opacity-100">
        <svg
          class="fill-current w-5 hide-on-minimal"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M13 18v2h6v2h-6a2 2 0 0 1-2-2v-2H8a4 4 0 0 1-4-4V7a1 1 0 0 1 1-1h3V2h2v4h4V2h2v4h3a1 1 0 0 1 1 1v7a4 4 0 0 1-4 4h-3zm-5-2h8a2 2 0 0 0 2-2v-3H6v3a2 2 0 0 0 2 2zm10-8H6v1h12V8zm-6 6.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
          />
        </svg>
      </div>
      <div v-if="pod.attendee && isPromoted && !isFloating" class="opacity-40 group-hover:opacity-100">
        <svg class="fill-current w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M2 19h20v2H2v-2zM2 5l5 3.5L12 2l5 6.5L22 5v12H2V5zm2 3.841V15h16V8.841l-3.42 2.394L12 5.28l-4.58 5.955L4 8.84z"
          />
        </svg>
      </div>
      <div
        v-if="pod.attendee && pod.video && pod.attendee.with_low_quality && !isFloating"
        class="text-xs font-medium opacity-40 group-hover:opacity-100"
      >
        Q. Baixa
      </div>
      <div
        v-if="pod.attendee && !pod.attendee.user.key && !isFloating && pod.video"
        class="text-xs font-medium opacity-40 group-hover:opacity-100"
      >
        Convidado
      </div>
    </div>

    <!-- Video paused -->
    <div v-if="!isFloating && pod.video && !pod.video.enabled" class="text-sm font-medium opacity-50 z-10">
      O vídeo foi colocado em pausa
    </div>

    <div
      :id="'video-' + pod.id"
      class="video-wrapper absolute top-0 left-0 z-0"
      @click="$emit('toggle-fullscreen', pod)"
    ></div>

    <div class="stats hidden"></div>
  </div>
</template>

<script>
import Avatar from '@/fw-modules/fw-core-vue/ui/components/users/Avatar'

export default {
  name: 'BlockRemoteStream',
  components: {
    Avatar,
  },

  props: {
    asWebinar: Boolean,
    pod: Object,
    podSize: Object,
    podsLength: Number,
    attendee: Object,
    withFullscreen: Boolean,
    fillPod: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    isPromoted() {
      return this.canPromotePod(this.pod) && this.asWebinar && this.pod.attendee.withRole('show_to_everyone')
    },
    loggedUser() {
      return this.$store.getters.getUser
    },
    isFloating() {
      return this.withFullscreen && !this.pod.fullscreen
    },
    isPlugin() {
      return this.pod.attendee && this.pod.attendee.plugin
    },
  },

  mounted() {
    this.pod.mounted()
  },
  beforeDestroy() {
    this.pod.beforeDestroy()
  },

  methods: {
    canPromotePod(pod) {
      return (
        !this.$device.isMobile() &&
        pod.attendee &&
        pod.attendee.canBePromoted() &&
        this.attendee.withRole('can_promote')
      )
    },
    toogleEpandedStream() {
      this.fillPod = !this.fillPod
    },
  },
}
</script>

<style lang="scss">
.remote-pod {
  .video-wrapper {
    @apply w-full h-full;
  }
  .video-wrapper video {
    @apply w-full h-full object-cover;
  }
  &.is-screen {
    .video-wrapper {
      video {
        @apply w-full h-full object-contain;
      }
    }
  }
}
</style>
