import Vue from 'vue'
import Vuex from 'vuex'

import messages from '@/i18n'

import actions from './actions'
import mutations from './mutations'
import getters from './getters'

import CoreStoreDefaults from '@/fw-modules/fw-core-vue/store/defaults'

import ServiceTasks from '@/fw-modules/fw-core-vue/tasks/services/ServiceTasks'
import ServiceActivity from '@/fw-modules/fw-core-vue/activity/services/ServiceActivity'

Vue.use(Vuex)

const state = Object.assign(CoreStoreDefaults.state, {
  api: {
    base: ServiceTasks,
    isActive: true,
  },
  activityApi: {
    base: ServiceActivity,
    isActive: true,
  },
})
state.locales = messages['pt']

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
})
