<template>
  <fw-layout
    :notfound="meetingNotFound"
    full
    :dark="isRunning"
    tapbar-on-desktop
    :back-to-enable="false"
    :header-session="false"
    mobile-ready
    :class="{ 'is-sharing-screen': screenShare.active }"
  >
    <template v-if="meeting && isRunning" #header-nav>
      <div class="flex gap-5 items-center text-base font-medium text-gray-300">
        <div class="flex-1 flex items-center gap-10">
          <div class="flex gap-2 items-center font-semibold is-family-code">
            <fw-icon-live class="w-5 h-5" />
            {{ meeting.alias && meeting.alias.length ? meeting.alias[0] : meeting.key }}
          </div>
          <div class="flex gap-3 items-center">
            <b-dropdown v-if="!isMobile" aria-role="list" :triggers="['click']">
              <fw-button-dropdown slot="trigger" role="button" type="transparent">
                <span class="text-gray-500 mr-1">Vista</span>
                {{ view == 'participants' ? 'Participantes' : 'Canais' }}
              </fw-button-dropdown>
              <b-dropdown-item
                aria-role="listitem"
                class="text-sm flex gap-5 justify-between"
                @click="setView('participants')"
              >
                <div class="font-semibold">Participantes</div>
                <div v-if="podsLength" class="px-3 py-0.5 rounded-full bg-gray-100 text-xs font-semibold">
                  {{ podsLength }}
                </div>
              </b-dropdown-item>
              <b-dropdown-item v-if="canSeeChat" class="text-sm flex gap-5 justify-between" @click="setView('chat')">
                <div class="font-semibold">Canais</div>
                <div v-if="chatAllUnread" class="px-3 py-0.5 rounded-full bg-primary text-white text-xs font-semibold">
                  {{ chatAllUnread }}
                </div>
              </b-dropdown-item>
            </b-dropdown>
            <div
              v-if="canSeeChat"
              class="flex gap-1.5 items-center text-sm font-semibold cursor-pointer"
              :class="{ 'text-primary': chatAllUnread, 'text-gray-700': !chatAllUnread }"
              @click="setView('chat')"
            >
              <div class="relative py-1">
                <fw-icon-chats class="w-5 h-5" />
                <fw-dot v-if="chatAllUnread" class="top-1 -right-0" />
              </div>
              <div v-if="chatAllUnread">{{ chatAllUnread }} novas</div>
            </div>
          </div>
          <div v-if="talkingNames && !isTabletOrMobile" class="text-primary flex gap-2 items-center">
            <div>
              <fw-icon-speaker class="w-5 h-5 flex-shrink-0" />
            </div>
            <div>
              <v-clamp autoresize :max-lines="1">{{ talkingNames }}</v-clamp>
            </div>
          </div>
        </div>
        <div
          v-if="
            (withJanus && (asWebinar || isWebinarActive)) ||
              (!canRecord && (instance.recording == 'running' || instance.recording == 'paused'))
          "
          class="flex items-center gap-5"
        >
          <b-tooltip
            v-if="!canRecord && (instance.recording == 'running' || instance.recording == 'paused')"
            :label="instance.recording == 'paused' ? 'A gravação está em pausa.' : 'Esta sessão está a ser gravada.'"
            type="is-dark"
            position="is-bottom"
            :animated="false"
          >
            <fw-icon-record-circle-solid
              v-if="instance.recording == 'running'"
              class="w-6 h-6 text-red-500 animate-pulse"
            />
            <fw-icon-pause-circle-solid v-else class="w-6 h-6 text-gray-400" />
          </b-tooltip>
          <div v-if="withJanus && (asWebinar || isWebinarActive)" class="font-semibold text-gray-300">WEBINAR</div>
        </div>
        <div v-if="withNetworkIssues || camera.withCPUIssues || screenShare.withCPUIssues">
          <fw-button
            type="transparent"
            class="text-red-500 bg-red-300 bg-opacity-10 animate-pulse"
            @click.native="toggleQualityModal()"
          >
            Ligação instável
          </fw-button>
        </div>
        <BlockSelfStreamDebug
          v-if="janus"
          :janus="janus"
          :instance="instance"
          :audio="audio"
          :camera="camera"
          :screen-share="screenShare"
        />
        <div v-if="duration" class="w-20 text-center">{{ duration }}</div>
        <div v-if="selfPodSize === 'minimal' && camera.active" class="w-24"></div>
      </div>
    </template>

    <template #main-content>
      <div class="h-full flex items-center justify-center">
        <div v-if="isRunning" class="w-full flex h-full">
          <BlockSelfStream
            v-if="isRunning && janus"
            :attendee="attendee"
            :audio="audio"
            :camera="camera"
            :screen-share="screenShare"
            :view="view"
            :pods-length="podsLength"
            :active-pod="activeFullscreenPod"
            @self-pod-size="selfPodSize = $event"
          />
          <PanelParticipants
            v-if="inParticipantsView"
            :view="view"
            :external="instance.external"
            :as-webinar="asWebinar"
            :pods="pods"
            :chats="chats"
            :chat-users="chatUsers"
            :active-pods="activePods"
            :pods-length="podsLength"
            :number-of-pages="numberOfPages"
            :active-page="activePage || 0"
            :current-page-pods="currentPagePods"
            :attendee="attendee"
            :pods-submenu="podsSubmenu"
            :chat-submenu="chatSubmenu"
            :all-chat-users="allChatUsers"
            :chat-active="chatActive"
            :with-fullscreen="withFullscreen"
            :floating-streams-visible="floatingStreamsVisible"
            :with-janus="withJanus"
            :fill-pod="pageSettings.fillPod"
            @set-view="setView"
            @change-page="changePage"
            @toggle-fullscreen="toggleFullscreen"
            @find-and-focus-pod="findAndFocusPod"
            @toggle-chat-submenu="toggleChatSubmenu"
            @toggle-pods-submenu="togglePodsSubmenu"
            @toggle-floating-streams-visibility="toggleFloatingStreamVisibility"
            @mute-attendee="muteAttendee"
            @kick-attendee="kickAttendee"
            @toggle-promote="togglePromote"
            @load-chat="loadChat"
            @load-main-chat="loadMainChat"
            @unload-chat="unloadChat"
            @set-chat-messages="setChatMessages"
            @delete-chat-message="deleteChatMessage"
            @go-to-user-chat="goToUserChat"
          />
          <PanelChats
            v-if="inChatMenuView"
            :meeting="meeting"
            :chats="chats"
            :chat-users="chatUsers"
            :all-chat-users="allChatUsers"
            :chat-active="chatActive"
            @load-chat="loadChat"
            @load-main-chat="loadMainChat"
            @unload-chat="unloadChat"
            @set-chat-messages="setChatMessages"
            @delete-chat-message="deleteChatMessage"
          />
          <PanelPlugin
            v-if="isRunning && view == 'plugin' && attendee && attendee.withRole('plugin_allowed') && !isMobile"
            :meeting-key="meeting.key"
          />
          <PanelRoomPlugin
            v-if="isRunning && view == 'room-plugin' && canSeeRoomPlugin && !isMobile"
            :meeting="meeting"
            :self-attendee="attendee"
            :attendees="attendees"
          />
          <PanelUnit
            v-if="view == 'unit' && allowClassSessionView"
            :edition="classEdition"
            :session="activeClassSession"
            :is-teacher="isClassEditionTeacher"
            @go-to-user-chat="goToUserChat"
          />
          <BlockHandsUpQueue
            v-if="isRunning && withHandsUp && view == 'participants'"
            :as-webinar="asWebinar"
            :attendee="attendee"
            :attendees-hands-up="attendeesHandsUp"
            :attendees="attendees"
            :with-janus="withJanus"
            :waiting-attendees="waitingAttendees"
            @toggle-fullscreen="toggleFullscreen"
            @find-and-focus-pod="findAndFocusPod"
            @toggle-promote="togglePromote"
            @lower-attendee-hand="lowerAttendeeHand"
          />
          <BlockWaitingAttendees
            v-if="isRunning && waitingAttendees.length && !ModalRequestToEnterIsActive"
            :waiting-attendees="waitingAttendees"
            @show-waiting-attendees="ModalRequestToEnterIsActive = true"
          />
        </div>
        <div v-else>
          <HallMeeting
            :loading="loading"
            :is-running="isRunning"
            :meeting="meeting"
            :is-meeting-owner="isMeetingOwner"
            :is-class-edition="isClassEdition"
            :waiting-to-be-accepted="waitingToBeAccepted"
            :enter-request-rejected="enterRequestRejected"
            :start-with-audio="startWithAudio"
            :start-with-camera="startWithCamera"
            @toggle-audio-start-option="startWithAudio = !startWithAudio"
            @toggle-camera-start-option="startWithCamera = !startWithCamera"
            @start-meeting="startMeeting"
            @personalize-meeting-key="ModalPersonalizeMeetingKeyIsActive = true"
          />
        </div>
      </div>
    </template>

    <template v-if="isRunning" #tapbar>
      <ToolboxCall
        :meeting="meeting"
        :is-running="isRunning"
        :instance="instance"
        :attendee="attendee"
        :audio="audio"
        :active-pods="activePods"
        :camera="camera"
        :screen-share="screenShare"
        :active-view="view"
        :pods-length="podsLength"
        :pods-submenu="podsSubmenu"
        :main-chats-length="chats.length"
        :chat-submenu="chatSubmenu"
        :chat-main-unread="chatMainUnread"
        :floating-streams-visible="floatingStreamsVisible"
        :is-toolbox-call-expanded="isToolboxCallExpanded"
        :is-toolbox-call-force-collapsed="isToolboxCallForceCollapsed"
        :allow-meeting-changes="allowMeetingChanges || (activeClassSession && isClassEditionTeacher)"
        :with-fullscreen="withFullscreen"
        :with-janus="withJanus"
        :can-record="canRecord"
        :camera-options="cameraOptions"
        :audio-input-options="audioInputOptions"
        @set-view="setView"
        @update-recordings="updateRecording"
        @leave-meeting="leaveMeeting"
        @end-meeting="endMeeting"
        @toggle-hand="toggleHand"
        @toggle-hd-mode="toggleHdMode"
        @mute-all="muteAll"
        @toggle-bulk-permissions="toggleBulkPermissions"
        @toggle-quality-modal="toggleQualityModal"
        @toggle-media-selection="toggleMediaSelection"
        @toggle-pods-gallery-config="togglePodsGalleryConfig"
        @toggle-toolbox-call="toggleToolboxCall"
        @toggle-floating-streams-visibility="toggleFloatingStreamVisibility"
        @toggle-pods-submenu="togglePodsSubmenu"
        @toggle-chat-submenu="toggleChatSubmenu"
      />
    </template>

    <template #modals>
      <ModalRequestEnter
        v-if="ModalRequestToEnterIsActive"
        :waiting-attendees="waitingAttendees"
        @close="ModalRequestToEnterIsActive = false"
        @accept-participants="acceptParticipants"
        @reject-participants="rejectParticipants"
        @open-remove-attendee-modal="openRemoveAttendeeModal"
      />
      <ModalPersonalizeMeetingKey
        v-if="meeting && isMeetingOwner"
        :show="ModalPersonalizeMeetingKeyIsActive"
        :intro="!ModalPersonalizeMeetingKeyIntroViewed"
        :meeting="meeting"
        @close="ModalPersonalizeMeetingKeyIsActive = $event"
      />
      <ModalBulkPermissions
        v-if="instance && bulkPermissionsModalActive"
        :show="bulkPermissionsModalActive"
        :meeting="meeting"
        :instance="instance"
        :attendees="attendees"
        :visibility="ModalBulkPermissionsVisibility"
        :is-class-edition="isClassEdition"
        :with-janus="withJanus"
        @close="resetPermissionsModal"
        @update-roles="updateRoles"
        @set-mode-webinar="setModeWebinar"
        @set-mode-meeting="setModeMeeting"
        @update-visibility-modal-bulk-permissions="updateVisibilityModalBulkPermissions"
      />
      <ModalAttendeePermissions
        :show="permissionsAttendeeModalActive"
        :attendee="permissionsAttendee"
        :meeting="meeting"
        @close="resetPermissionsModal"
        @reload-page="reloadPage"
      />
      <ModalMediaErrors
        v-if="instance"
        :camera="camera"
        :audio="audio"
        :screen-share="screenShare"
        :dummy-audio="dummyAudio"
      />
      <ModalQualityConfigurations
        v-if="instance"
        :attendee="attendee"
        :show-quality-modal="showQualityModal"
        :camera="camera"
        :pods-length="pods.length"
        :allow-to-disable-cameras="allowToDisableCameras"
        :cameras-disabled="camerasDisabled"
        :lowest-quality="lowestQuality"
        :screen-share="screenShare"
        :page-settings="pageSettings"
        :with-network-issues="withNetworkIssues"
        @toggle-quality-modal="toggleQualityModal"
        @toggle-hd-mode="toggleHdMode"
        @toggle-lowest-quality="toggleLowestQuality"
        @toggle-all-videos="toggleAllVideos"
        @toggle-pods-gallery-config="togglePodsGalleryConfig"
      />
      <ModalMediaSelection
        v-if="instance && showMediaSelection"
        :show-media-selection="showMediaSelection"
        :audio="audio"
        :camera="camera"
        :audio-output="audioOutput"
        :devices-error="devicesError"
        :camera-options="cameraOptions"
        :audio-input-options="audioInputOptions"
        :audio-output-options="audioOutputOptions"
        @toggle-media-selection="toggleMediaSelection"
        @toggle-mirror-video="toggleMirrorVideo"
      />
      <ModalPodsGalleryConfigurations
        v-if="instance && showPodsGalleryConfig"
        :show-pods-gallery-config="showPodsGalleryConfig"
        :page-settings="pageSettings"
        @toggle-pods-gallery-config="togglePodsGalleryConfig"
      />
      <ModalForm
        v-if="isRunning && activeForm"
        :form="activeForm"
        @close="activeForm = null"
        @remove-form="removeForm"
      />
      <ModalRemoveAttendee
        v-if="ModalRemoveAttendee"
        :data="ModalRemoveAttendee"
        :is-exam="isExam"
        @call="callRemoveAttendeeModal"
        @close="closeRemoveAttendeeModal"
      />
      <ModalAudioLocked
        v-if="withJanus && showAudioLockedModal"
        :show-audio-locked-modal="showAudioLockedModal"
        @close-modal="showAudioLockedModal = false"
      />
      <ModalAudioUnlocked
        v-if="withJanus && showAudioUnlockedModal"
        :show-audio-unlocked-modal="showAudioUnlockedModal"
        :with-janus="withJanus"
        :attendee="attendee"
        :audio="audio"
        :audio-input-options="audioInputOptions"
        @close-modal="showAudioUnlockedModal = false"
      />
      <div id="audio-streams" class="is-hidden"></div>
    </template>
  </fw-layout>
</template>

<script>
import BaseLive from '@/fw-modules/fw-meetings-vue/mixins/BaseLive'
import ChatLive from '@/fw-modules/fw-core-vue/chats/mixins/ChatLive'
import JanusLive from '@/fw-modules/fw-meetings-vue/mixins/JanusLive'
import JanusSelfLive from '@/fw-modules/fw-meetings-vue/mixins/JanusSelfLive'
import JanusSubscribersLive from '@/fw-modules/fw-meetings-vue/mixins/JanusSubscribersLive'
import PodsLive from '@/fw-modules/fw-meetings-vue/mixins/PodsLive'
import ToolboxCall from '@/fw-modules/fw-meetings-vue/components/toolbars/ToolboxCall'
import ModalPersonalizeMeetingKey from '@/fw-modules/fw-meetings-vue/components/modals/ModalPersonalizeMeetingKey'
import ModalAttendeePermissions from '@/fw-modules/fw-meetings-vue/components/modals/ModalAttendeePermissions'
import ModalBulkPermissions from '@/fw-modules/fw-meetings-vue/components/modals/ModalBulkPermissions'
import ModalRequestEnter from '@/fw-modules/fw-meetings-vue/components/modals/ModalRequestEnter'
import ModalMediaErrors from '@/fw-modules/fw-meetings-vue/components/modals/ModalMediaErrors'
import ModalQualityConfigurations from '@/fw-modules/fw-meetings-vue/components/modals/ModalQualityConfigurations'
import ModalMediaSelection from '@/fw-modules/fw-meetings-vue/components/modals/ModalMediaSelection'
import ModalPodsGalleryConfigurations from '@/fw-modules/fw-meetings-vue/components/modals/ModalPodsGalleryConfigurations'
import ModalForm from '@/fw-modules/fw-meetings-vue/components/modals/ModalForm'
import ModalRemoveAttendee from '@/fw-modules/fw-meetings-vue/components/modals/ModalRemoveAttendee'
import ModalAudioLocked from '@/fw-modules/fw-meetings-vue/components/modals/ModalAudioLocked'
import ModalAudioUnlocked from '@/fw-modules/fw-meetings-vue/components/modals/ModalAudioUnlocked'
import BlockSelfStream from '@/fw-modules/fw-meetings-vue/components/blocks/BlockSelfStream'
import BlockHandsUpQueue from '@/fw-modules/fw-meetings-vue/components/blocks/BlockHandsUpQueue'
import BlockWaitingAttendees from '@/fw-modules/fw-meetings-vue/components/blocks/BlockWaitingAttendees'
import BlockSelfStreamDebug from '@/fw-modules/fw-meetings-vue/components/blocks/BlockSelfStreamDebug'
import PanelParticipants from '@/fw-modules/fw-meetings-vue/components/panels/PanelParticipants'
import PanelChats from '@/fw-modules/fw-core-vue/chats/components/panels/PanelChats'
import PanelUnit from '@/fw-modules/fw-core-vue/academic/components/panels/PanelUnit'
import HallMeeting from '@/fw-modules/fw-meetings-vue/components/panels/HallMeeting'

export default {
  components: {
    BlockSelfStream,
    BlockHandsUpQueue,
    BlockWaitingAttendees,
    BlockSelfStreamDebug,
    PanelParticipants,
    PanelUnit,
    PanelChats,
    ToolboxCall,
    HallMeeting,
    ModalRequestEnter,
    ModalPersonalizeMeetingKey,
    ModalAttendeePermissions,
    ModalBulkPermissions,
    ModalMediaErrors,
    ModalQualityConfigurations,
    ModalPodsGalleryConfigurations,
    ModalMediaSelection,
    ModalRemoveAttendee,
    ModalForm,
    ModalAudioLocked,
    ModalAudioUnlocked,
  },

  mixins: [BaseLive, ChatLive, JanusLive, JanusSelfLive, JanusSubscribersLive, PodsLive],

  computed: {
    isDark() {
      return this.isRunning
    },
    inChatMenuView() {
      return this.isInChatMenuView()
    },
    canSeeRoomPlugin() {
      return Boolean(this.attendee && this.attendee.withRole('can_start_room'))
    },
    canSeeChat() {
      return Boolean(this.loggedUser && !this.loggedUser.isAnonymous)
    },
    canAddPlugin() {
      return Boolean(this.withJanus && this.attendee && this.attendee.withRole('plugin_allowed'))
    },
    canSeeRecordings() {
      // Only user meetings for now
      return Boolean(
        (process.env.VUE_APP_KEY == 'ucteacher' || process.env.VUE_APP_KEY == 'ucmeetings') &&
          this.loggedUser &&
          this.meeting &&
          (this.meeting.item_type == 'user' || this.meeting.item_type == 'class_edition') &&
          this.attendee &&
          this.attendee.withRole('can_record')
      )
    },
    canRecord() {
      return Boolean(this.withJanus && this.canSeeRecordings)
    },
    canSeeForms() {
      return Boolean(this.loggedUser && this.attendee && this.attendee.withRole('form_allowed'))
    },
    isTabletOrMobile() {
      return this.isMobile || this.$device.isTablet()
    },
  },

  mounted() {
    if (this.$device.isiOS) {
      window.addEventListener('resize', this.fix100vhiOSBug)
      this.fix100vhiOSBug()
    }
  },

  beforeDestroy() {
    if (this.$device.isiOS) {
      window.removeEventListener('resize', this.fix100vhiOSBug)
    }
  },

  methods: {
    isInParticipantsChatView() {
      // Should match PanelParticipants.inParticipantsChatView
      return this.view === 'participants' && this.chatSubmenu
    },
    isInChatMenuView() {
      return this.isRunning && this.view === 'chat' && !this.isMobile
    },
    isInChatView() {
      return this.isInParticipantsChatView() || this.isInChatMenuView()
    },
    goToUserChat(user) {
      this.view = 'chat'
      this.chatOnLoadGoToUser = user
    },

    resetPermissionsModal() {
      this.bulkPermissionsModalActive = false
      this.permissionsAttendeeModalActive = false
      this.permissionsAttendee = null
    },

    fix100vhiOSBug() {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    },

    openRemoveAttendeeModal(data) {
      this.ModalRemoveAttendee = data
    },
    async callRemoveAttendeeModal(data) {
      await this.ModalRemoveAttendee.callback(data)
      this.closeRemoveAttendeeModal()
    },
    closeRemoveAttendeeModal() {
      this.ModalRemoveAttendee = null
    },
  },
}
</script>
