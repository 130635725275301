<template>
  <b-modal
    :active.sync="isActive"
    :on-cancel="closeModal"
    has-modal-card
    aria-role="dialog"
    aria-modal
    class="meetings-modal-pods-enter-queue has-overflow"
  >
    <div class="modal-card is-width-small">
      <section class="modal-card-body">
        <div class="is-flex is-flex-space-between has-margin-bottom-medium">
          <div class="is-flex is-flex-align-center">
            <div class="label is-size-3">Configurar áudio e vídeo</div>
          </div>
          <div>
            <b-button type="is-text" icon-right="times" @click="closeModal">Fechar</b-button>
          </div>
        </div>

        <div v-if="devicesError" class="notification has-padding is-danger has-text-small has-margin-top">
          Não foi possível identificar os dispositivos do seu sistema. Por favor, confirme que tem todas permissões de
          acesso aos dispositivos áudio e vídeo ativas no seu navegador (browser).
        </div>
        <div v-else>
          <h2 class="is-size-4">Câmara</h2>
          <div v-if="!cameraOptions.length" class="has-text-small has-text-muted">Sem opção de saida de video.</div>
          <div v-else class="has-margin-top has-margin-bottom">
            <b-dropdown aria-role="list">
              <button slot="trigger" slot-scope="{ active }" class="button is-light">
                <b-icon size="is-small" icon="video"></b-icon>
                <span>{{ getActiveDevice(cameraOptions, camera).label || 'Default' }}</span>
                <b-icon size="is-small" :icon="active ? 'chevron-up' : 'chevron-down'"></b-icon>
              </button>
              <b-dropdown-item
                v-for="device in cameraOptions"
                :key="device.id"
                aria-role="listitem"
                @click="camera.setDevice(device.id)"
                >{{ device.label }}</b-dropdown-item
              >
            </b-dropdown>
          </div>

          <h2 class="is-size-4 has-margin-top-medium">Microfone</h2>
          <div v-if="!audioInputOptions.length" class="has-text-small has-text-muted">
            Sem opção de entrada de audio.
          </div>
          <div v-else class="has-margin-top has-margin-bottom">
            <b-dropdown aria-role="list">
              <button slot="trigger" slot-scope="{ active }" class="button is-light">
                <b-icon size="is-small" icon="microphone"></b-icon>
                <span>{{ getActiveDevice(audioInputOptions, audio).label || 'Default' }}</span>
                <b-icon size="is-small" :icon="active ? 'chevron-up' : 'chevron-down'"></b-icon>
              </button>
              <b-dropdown-item
                v-for="device in audioInputOptions"
                :key="device.id"
                aria-role="listitem"
                @click="audio.setDevice(device.id)"
                >{{ device.label }}</b-dropdown-item
              >
            </b-dropdown>
          </div>

          <h2 class="is-size-4 has-margin-top-medium">Altifalante</h2>
          <div v-if="!audioOutputOptions.length" class="has-text-small has-text-muted">
            Sem opção de saida de audio.
          </div>
          <div v-else class="has-margin-top has-margin-bottom">
            <b-dropdown aria-role="list">
              <button slot="trigger" slot-scope="{ active }" class="button is-light">
                <b-icon size="is-small" icon="volume-up"></b-icon>
                <span>{{ getActiveDevice(audioOutputOptions, audioOutput).label || 'Default' }}</span>
                <b-icon size="is-small" :icon="active ? 'chevron-up' : 'chevron-down'"></b-icon>
              </button>
              <b-dropdown-item
                v-for="device in audioOutputOptions"
                :key="device.id"
                aria-role="listitem"
                @click="audioOutput.setDevice(device.id)"
                >{{ device.label }}</b-dropdown-item
              >
            </b-dropdown>
          </div>

          <h2 class="is-size-4 has-margin-top-medium">Outras opções</h2>
          <div class="has-margin-top">
            <div class="is-flex is-flex-align-center">
              <b-checkbox v-model="mirrorVideo" @input="$emit('toggle-mirror-video')">
                Modo espelho na câmara principal
              </b-checkbox>
              <b-button
                size="is-small"
                type="is-text"
                aria-label="Mais info"
                icon-left="question-circle"
                @click="infoBoxes.mirrorVideo = !infoBoxes.mirrorVideo"
              ></b-button>
            </div>
            <div v-if="infoBoxes.mirrorVideo" class="has-text-small has-text-muted">
              Esta opção é ativada apenas no seu dispositivo. Os restantes participantes recebem o seu vídeo na forma
              original.
            </div>
          </div>
        </div>
        <div class="has-text-tiny has-margin-top-medium has-text-muted">
          Estas definições são guardadas para todas as sessões realizadas neste dispositivo.
        </div>
      </section>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    showMediaSelection: Boolean,
    audio: Object,
    camera: Object,
    audioOutput: Object,
    devicesError: Boolean,
    cameraOptions: Array,
    audioInputOptions: Array,
    audioOutputOptions: Array,
  },

  data() {
    return {
      isActive: this.showMediaSelection,
      mirrorVideo: !(Boolean(localStorage.getItem('device.video.mirror.disabled')) || false),
      infoBoxes: {
        mirrorVideo: false,
      },
    }
  },

  watch: {
    showMediaSelection(value) {
      this.isActive = value
    },
  },

  methods: {
    closeModal() {
      this.$emit('toggle-media-selection')
      this.$emit('close', false)
    },

    getActiveDevice(options, active) {
      if (active.deviceId) {
        return options.find(device => device.id === active.deviceId)
      }
      return false
    },
  },
}
</script>
